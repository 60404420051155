/* -------------------- MOBILE START -------------------------*/

#games_02 .section_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: end;
}

#games_02 .section_header.titlePosition_center{
  justify-content: center;
  text-align: center;
}

#games_02 .section_title {
  margin-bottom: .5rem;
  display: flex;
  align-items: center;
}

/* #games_02 .section_title img {
  max-width: 2.5rem;
  margin-right: 0.5rem;
  width: auto;
  height: 2rem;
} */

#games_02 .section_details {
  margin-left: .25rem;
  margin-right: .25rem;
  text-decoration: none;
}

#games_02 .main_game.section {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

/* IF GAME SELECTION MENU IS Horizontal */
#games_02 .main_gameSelection {
  display: flex;
  align-items: center;
  flex-direction: column;
}

#games_02 .main_gameSelection .main_gameSelection_menu {
  display: flex;
  align-items: center;
  flex-direction: row;
}

#games_02 .main_gameSelection .main_gameSelection_menu img {
  /* width: 3.5rem; */
  width: auto;
  max-height: 2.5rem;
  border-radius: inherit;
}



/* IF GAME SELECTION MENU IS Vertical */

#games_02 .main_gameSelection_vertical {
  display: flex;
  align-items: center;
  flex-direction: column;
}

#games_02 .main_gameSelection_vertical .main_gameSelection_menu {
  display: flex;
  align-items: center;
  flex-direction: column;
}

/* #games_02 .main_gameSelection_vertical .main_gameSelection_menu img {
  width: auto;
  max-height: 7rem;
  border-radius: inherit;
} */


/* --------------------------------------- */

#games_02 .main_game_wrap {
  display: flex;
  flex-direction: row;
}

#games_02 .main_game_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  max-height: 60.5rem;
  overflow: auto;
}

#games_02 .main_game_container a {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: var(--h5-font-size);
}



/* #games_02 .main_game_wrap_SL a,
    #games_02 .main_game_wrap_LC a {
      width: 50%;
    } */

/* #games_02 .main_game_wrap_SP a,
    #games_02 .main_game_wrap_APP a,
    #games_02 .main_game_wrap_FI a,
    #games_02 .main_game_wrap_LO a {
      width: 50%;
      margin-top:1rem;
    } */

/* #games_02 .main_game_container a {
      width: 50%;
    } */



#games_02 .main_gameSelection_width {
  width: 100%;
}


/*--- VERTICAL LAYOUT ---*/
#games_02 .main_game_wrap_vertical {
  display: flex;
  flex-direction: column;
}

#games_02 .vertical_col {
  width: 100%;
}

#games_02 .main_game_wrap_vertical .main_gameSelection {
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  overflow: auto;
}

#games_02 .main_game_wrap_vertical .main_game_container {

  margin-top: 1rem;
  padding: 1rem;
  min-height: 10rem;
  max-height: 35rem;
}

#games_02 .main_game_wrap_vertical .main_gameSelection_width {
  width: 25%;
  min-width: 25%;
}

#games_02 .main_game_wrap_vertical .main_gameSelection_menu img {
  max-height: 4rem;
}

/* IF SELECTED TYPE IS HOT GAME */

#games_02 .main_game_container .main_game_hotGameContainer {
  padding: .5rem 1rem;
  border-radius: .5rem;
}

#games_02 .main_game_container .main_game_hotGameContainer img {
  border-radius: inherit;
}

/* Desktop to Mobile */
@media (max-width: 992px) {

  #games_02 .main_gameSelection .main_gameSelection_menu {
    font-size: 1.25rem;
    white-space: nowrap;
  }

  #games_02 .main_game_name {
    font-size: 1.25rem;
  }

}


@media (max-width: 450px) {

  #games_02 .main_gameSelection .main_gameSelection_menu {
    font-size: 1.35rem;
  }

  #games_02 .main_game_name {
    font-size: 1.25rem;
    text-align: center;
  }

}

@media (max-width: 340px) {

  #games_02 .main_gameSelection .main_gameSelection_menu {
    font-size: 1.25rem;
  }

  #games_02 .main_game_name {
    font-size: 1.25rem;
    text-align: center;
  }

}

/* -------------------- MOBILE END -------------------------*/

/*  -------------------- DESKTOP START ------------------------- */
/* Mobile to Desktop */
@media (min-width: 992px) {

  #games_02 .main_game_container a {
    /* width: 20%; */
    text-align: center;
  }

}

.game_wrap_title{
  min-height: 3.5rem;
}

/*------ HOT GAMES START------*/
/* SLIDER */
#mainGame_hotGame_01 {
  max-width: 100%;
  z-index: 1;
  margin: unset;
  text-align: center;
  height: 100%;
}

#mainGame_hotGame_01 .swiper-button-next,
#mainGame_hotGame_01 .swiper-button-prev {
  color: #000;
}

#mainGame_hotGame_01 .swiper-pagination-bullets.swiper-pagination-horizontal{
  bottom: 0;
  /* position: relative; */
  padding: .5rem;
}

#mainGame_hotGame_01 .swiper-pagination-bullet-active{
  width: 2rem;
  border-radius: .25rem;
  transition: all .25s;
}

/* #mainGame_hotGame_01 img{
  width: 100%;
} */

#games_02 .game_wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
}

#games_02 .game_wrap .hot_game_wrap {
  width: 30%;
}

#games_02 .game_wrap .hot_game_wrap .hot_game_container{
  padding: 1.5rem;
  height: 100%;
  margin-top:-3rem;
}

#games_02 .game_wrap .hot_game_wrap .hot_game_box{
  position: relative;
  top:50%;
  transform: translate(0,-50%);
}

#games_02 .game_wrap .hot_game_wrap .hot_game_box img{
 border:1px solid #ccc;
 padding:1rem;
border-radius: 1rem;

}

#games_02 .game_wrap .hot_game_wrap .mainGame_hotGame_title{
  margin-top: .75rem;
}

/*------ HOT GAMES END------*/

#mainGame_game_01 .swiper-pagination-bullets.swiper-pagination-horizontal{
  bottom: 0;
  position: relative;
  padding: .5rem;
}

#mainGame_game_01 .swiper-pagination-bullet-active{
  width: 2rem;
  border-radius: .25rem;
  transition: all .25s;
}


#games_02 .game_wrap .main_game_wrap {
  width: 70%;
  display: flex;
  flex-direction: column;
}

#games_02 .game_wrap .main_game_wrap .main_gameSelection {
  display: flex;
  align-items: center;
  flex-direction: row;
}

#games_02 .game_wrap .main_gameSelection_width {
  width: auto;
}

#games_02 .game_wrap .main_game_wrap .main_game_container {
  width: 100%;
  display: unset;
  min-height: 30rem;
  /* position: relative;
  top:50%;
  transform: translate(0,-50%); */
}


