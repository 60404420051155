/* ------------------- Header Start --------------------- */
#header_03 {
  position: fixed;
  width: 100%;
  z-index: 5;
}

#header_03 .vl {
  border-left: 1px dashed #fff;
  margin: 0 0.5rem;
  height: 1.2rem;
}

/* ------ Header TopBar (row1) Start ------- */
#header_03 .header_topBar_container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  height: 100%;
}



#header_03 .header_topBar {
  padding: 0.5rem 0;
  height: 5rem;
  z-index: 5;
}

#header_03 .header_topBar_wrap {
  justify-content: flex-end;
  align-items: flex-end;
  flex-grow: 1;
  /* flex-direction: column */
}

/* --- header logo start --- */
#header_03 .header_logo {
  padding: 0;
}


#header_03 .header_logo.noSideBar img {
  height: 3.5rem;
  width: auto;
  margin-left: 1rem;
}

/* --- header logo end --- */

/* --- header form start --- */


#header_03 .header_topBar_btn {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-right: 1.25rem;
}

#header_03 .header_topBar_btn button:first-of-type {
  margin-right: 0.5rem;
}



#header_03 .header_topBar_btn .header_noti img {
  width: 2.5rem;
  /* margin: 1.5rem; */
}

#header_03 .header_topBar_btn .header_user img {
  width: 2.5rem;
  cursor: pointer;
}

#header_03 .header_topBar_btn .header_noti svg {
  position: absolute;
  color: red;
  right: -0.5rem;
  top: -0.5rem;
}

#header_03 .header_topBar_btn .header_user svg {
  position: absolute;
  color: red;
  right: -0.5rem;
  top: -0.7rem;
}

/* #header_03 .header_form button:first-of-type {
  margin-right: 0.25rem;
} */

/* #header_03 .header_form_wrap {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 2rem;
}

#header_03 .header_form_wrap .header_form {
  display: flex;
  flex-direction: row;
  height: 100%;
} */

/* #header_03 .forgot_content {
  margin-right: 0.5rem;
} */

/* #header_03 .forgot_content .forgot_content_username,
#header_03 .forgot_content .forgot_content_password {
  color: red;
  cursor: pointer;
} */

/* #header_03 .header_form .input_username,
#header_03 .header_form .input_password {
  margin-right: 0.25rem;
  width: 9rem;
  font-size: var(--h6-font-size);
  height: 100%;
} */

#header_03 .header_btnLogin,
#header_03 .header_btnRegister,
#header_03 .header_btnLogout {
  text-transform: uppercase;
  min-width: 7rem;
  font-weight: 600;
  white-space: nowrap;
  padding: 0.5rem 1rem;
  height: 100%;
  font-size: 1rem;

}

#header_03 .header_btnRegister,
#header_03 .header_btnRegister:hover,
#header_03 .header_btnLogout,
#header_03 .header_btnLogout:hover {
  margin: 0;
}

/* #header_03 .header_form .input_username,
#header_03 .header_form .input_password {
  margin-right: 0.25rem;
  width: 9rem;
} */

/* #header_03 .header_form+a {
  height: 100%;
} */

/* --- header form end --- */

/* ------------------ Header Menu Start ------------------- */

#header_03 .header_menu_page .header_menu_content {
  display: flex;
  align-items: center;
  flex-direction: row;
  text-decoration: none;
  padding-top: .5rem;
  padding-bottom: .5rem;
  padding-left: calc(var(--bs-gutter-x) * .5);
  padding-right: calc(var(--bs-gutter-x) * .5);
  height: 100%;
}

#header_03 .header_menu_page .header_text {
  white-space: nowrap;
  margin-left: 0.35rem;
  font-weight: 600;
  line-height: normal;
}

/* #header_03 .header_menu_page .header_text:hover {
  color: var(--color-active)
} */


/* ------ Header Menu (Sidebar mobile) Start ------- */
#header_03 .header_topBar_toolbar {
  display: flex;
  align-items: center;
}

#header_03 .header_topBar_toolbar button {
  border: 0;
  font-size: 2.5rem;
  margin-right: .5rem;
}

/* #header_03 .header_topBar_toolbar .navbar-toggler-icon { */
/* filter: brightness(0) invert(1); */
/* border: 0; */
/* } */



#header_menu_expand_lg.offcanvas.offcanvas-end {
  right: unset;
  left: 0;
  transform: translateX(-100%);
  width: 50%;
}

.header_sideBar.offcanvas {
  /* background: var(--color-primary); */
  color: #fff;
}

#header_menu_expand_lg.offcanvas.show:not(.hiding),
#header_menu_expand_lg.offcanvas.showing {
  transform: none;
}



.header_sideBar_logo {
  /* margin-top:1rem;
  margin-left:1rem;
  margin-right:1rem;
  margin-bottom: 0; */
  /* margin: .5rem;
  width: 65%; */
}

.header_sideBar_menu {
  display: flex;
  flex-direction: column;
}

.header_sideBar_body.offcanvas-body {
  padding: 0;
}

/* .header_sideBar_menu  {
  margin: 0.5rem 0;
} */
.header_sideBar_menu .header_menu_content {
  padding: 1rem 1.75rem;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.header_sideBar_menu summary.header_menu_content+.sidebar_language_selection p {
  padding: 0.5rem 2rem;
  font-size: 1rem;
  margin: 0 .85rem;
}

.header_sideBar_menu details[open] summary.header_menu_content {
  border-bottom-style: dashed;
}

/* .header_sideBar_menu  summary.header_menu_content+ .sidebar_language_selection p:last-child{
  border-bottom: 1px solid #ccc;
} */



.header_sideBar_menu .header_menu_icon {
  width: 2.5rem;
  margin: -0.25rem 1rem 0 0;
  /* background-image: none; */
}


#header_03 .language_header {
  display: flex;
  align-items: center;
  /* padding-top: .5rem;
  padding-bottom: .5rem; */
  position: relative;
  flex-direction: row;
  margin-left: .35rem;
}

#header_03 .language_header img {
  width: 2.5rem;
  margin: 0 1.5rem;
}

#header_03 .language_header .header_current_language {
  margin-left: .5rem;
  margin-right: .5rem;
}

#header_03 .language_header .language_dropdown {
  display: none;
  position: absolute;
  top: 100%;
  z-index: 2;
  width: 100%;
  padding: 1rem 0 0;
}

#header_03 .language_header:hover .language_dropdown {
  display: block;
}

#header_03 .language_header .language_dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  font-size: 0.8rem;
}

#header_03 .language_header .language_dropdown ul li {
  padding: 0.5rem;
  background: #333;
  cursor: pointer;
}

#header_03 .language_header .language_dropdown ul li:hover {
  background: rgb(35, 32, 32);
  color: #fff;
}

/* ------ Header Menu (Sidebar mobile) End ------- */

/* ------ Header Menu (desktop) Start ------- */

#header_03 .header_menu {
  text-align: center;
  text-transform: uppercase;
  position: inherit;
  height: 3rem;
  z-index: 4;
  display: flex;
  flex: 1;
  /* padding-right: 1.5rem; */
}

#header_03.header_newRowMenu .header_menu {
  width: 100%;
  height: 3.25rem;

}

#header_03 .header_menu_container {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  flex-direction: row;
  height: 100%;
}

/* #header_03 .header_menu_left {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 100%;
} */

/* 
#header_03 .header_menu_right {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
} */


#header_03 #dropdown-headermenu {
  background: none;
  border: 0;
  color: inherit;
  padding: 0 .5rem;
  font-weight: 600;
  text-transform: uppercase;
}

#header_03 #dropdown-languagemenu {
  background: none;
  border: 0;
  color: inherit;
  padding: 0;
  cursor: pointer;
}

.dropdown_headermenu_language img {
  width: 2rem;
  margin-left: .35rem;
  filter: drop-shadow(rgb(155, 155, 155) 1px 1px 2px);
}

#header_03 #dropdown-headermenu::after,
#header_03 #dropdown-languagemenu::after {
  content: none;
}



/* .dropdown_content_container a{
  display: flex;
  flex-direction: column;
} */

#header_03 .dropdown_content_container a {
  width: 15%;
  display: flex;
  flex-direction: column;
  padding: 0 0.5rem 1rem;
  font-size: .7rem;
  text-decoration: none;
  transition: all .3s;
  align-items: center;
  /* filter: brightness(0.5); */
}

#header_03 .dropdown_content_container a:hover {
  filter: brightness(1);
  transform: translateY(-5px);
}

#header_03 .dropdown_content_wrap {
  max-height: 65vh;
  overflow-y: auto;
}

#header_03 .dropdown_content_wrap a img {
  width: 7.643rem;
  margin: auto;
}

#header_03 .dropdown_content {
  display: none;
  position: absolute;
  width: 100%;
  left: 0;
  z-index: 2;
  top: 100%;
  /* background: rgb(0, 0, 0, 0.8); */
  padding: 2rem 0;
  /* color: #fff; */
}

#header_03 .dropdown_content .dropdown_content_wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  margin: 0 1.5rem;
  flex-direction: row;
}

/* .dropdown_content a {
  width: 20%;
} */
/*   
.dropdown_content a img {
  width: 12rem;
} */
#header_03 .header_menu_page:hover .dropdown_content {
  display: block;
}

#header_03 .headerDropdownSwiper {
  /* padding: 0 1.6rem; */
  position: unset;
}

#header_03 .headerDropdownSwiper .swiper-button-next,
.swiper-rtl .swiper-button-prev {
  right: 5% !important;
}

#header_03 .headerDropdownSwiper .swiper-button-prev,
.swiper-rtl .swiper-button-next {
  left: 5% !important;
}

#header_03 .headerDropdownSwiper .swiper-button-next:after,
.headerDropdownSwiper .swiper-button-prev:after {
  font-size: 2.2rem;
}

/* ------ Header Menu (desktop) End ------- */

/* ------------------ Header Menu Start ------------------- */

/*------------------- Header Banking Info Start -------------------*/

/* -------  Mobile Start ------- */
#header_03 .header_banking_info {
  /* display: flex;
  justify-content: center;
  align-items: stretch; */
  /* padding: 0.75rem 0; */
  /* background: #fff;  
color:#000; */
  /* flex-direction: column;
  position: relative; */
}

#header_03 .header_banking_info .header_banking_info_container {
  display: flex;
  justify-content: center;
  align-items: stretch;
  /* padding: 0.75rem 0; */
  /* background: #fff;  
color:#000; */
  flex-direction: column;
  position: relative;
}

#header_03 .header_banking_info_top {
  display: flex;
  justify-content: start;
  align-items: stretch;
  /* padding: 0.75rem 0; */
  /* background: #fff;  
color:#000; */
  flex-direction: row;
  position: relative;
  /* margin: 0 .5rem; */
  margin: 0.3rem 0 0 .75rem;
}

#header_03 .header_banking_info_bottom {
  margin: 0.3rem .5rem 0;
}

#header_03 .header_banking_info .header_banking_info_left {
  display: flex;
  /* align-items: flex-end; */
  flex-direction: column;
  min-width: 15rem;
  justify-content: center;
  margin-right: .25rem;
}

#header_03 .header_banking_info .header_balance {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

#header_03 .header_banking_info_left .header_balance_wrap {
  display: flex;
  flex-direction: column;
  align-items: start;
  /* justify-content: space-around; */
  /* margin-right: .5rem; */
}

#header_03 .header_banking_info_left .header_balance .header_balance_amount {
  display: flex;
  flex-direction: row;
  width: 100%;
  /* justify-content: flex-end; */
}

#header_03 .header_banking_info_left .header_balance .header_balance_amount span {
  /* min-width: 9rem; */
  text-align: end;
  padding-bottom: 0.25rem;
  display: flex;
  flex: 1;
  justify-content: end;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 0.35rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
}

#header_03 .header_banking_info .header_balance+.header_balance_icon svg {
  margin: 0;
  cursor: pointer;
  margin-top: 15px;


}

#header_03 .header_banking_info .header_balance_currency {
  text-transform: uppercase;
  margin-right: .25rem;
  white-space: nowrap;
  width: 100%;
}

#header_03 .header_banking_info .header_balance_icon {
  /* padding-bottom: .35rem; */
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: default;
}


#header_03 .header_banking_info .header_balance_icon svg,
#header_03 .header_bottom_row .header_balance_icon svg {
  margin: 0 .5rem;
  cursor: pointer;
  padding-bottom: .35rem;
  font-size:1.5rem
}

#header_03 .header_banking_info .header_balance_icon img {
  /* margin-left: .5rem; */
  /* width: 1.65rem; */
  width: 1.65rem;
  cursor: pointer;
}

#header_03 .header_banking_info .header_banking_info_bottom .header_balance_icon img {
  /* margin-left: .5rem; */
  /* width: 1.65rem; */
  width: 2.45rem;
  cursor: pointer;
}

#header_03 .header_banking_info .header_bankinginfo_actionbtn {
  display: flex;
  flex-direction: row;
}

#header_03 .header_banking_info .header_bankinginfo_actionbtn button:first-of-type {
  margin-left: 0rem;
  margin-right: .5rem;
}

#header_03 .header_banking_info .header_btnRestore,
#header_03 .header_banking_info .header_btnPromoClaim {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* width: 50%; */
  padding: 0rem .5rem;
  margin-left: 0.25rem;
  white-space: nowrap;
  text-transform: uppercase;
  min-width: 7rem;
  line-height: normal;
  flex: 1;
  justify-content: center;
  font-size: 1rem;
  /* flex: 1; */
}

#header_03 .header_banking_info .header_banking_info_right {
  display: flex;
  align-items: center;
  flex: 1;
}

#header_03 .header_banking_info .header_banking_info_right .header_banking_menu_list a {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin-right: .5rem; */
  padding: .35rem;
  height: 80%;
  justify-content: center;
  flex: 1;
}

#header_03 .header_banking_info .header_banking_info_right .header_banking_menu_list a img {
  width: 3.5rem;
  margin-bottom: 0.25rem;
}

#header_03 .game_wallet_modal li {
  margin: 0.3rem 0;
  display: flex;
  align-items: center;
  padding: 0.5rem .8rem;
  border-radius: .5rem;
  justify-content: space-between;
  flex-direction: row;

}

#header_03 .header_topBar+.header_banking_info {
  height: 13rem;
}

#header_03 .header_topBar+.header_banking_info.enableLoyalty {
  height: 15rem;
}

/* -------  Mobile End ------- */

/* -------  Desktop Top ------- */
#header_03 .header_topBar_wrap .header_banking_info {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 0 0 0.25rem 0;
  background: transparent;
  flex-direction: row;
  min-height: 4.2vh;
  /* margin-right: 2.15rem; */
}

#header_03 .header_topBar_wrap .header_banking_info .header_balance,
#header_03 .header_topBar_wrap .header_bottom_row .header_balance {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 0.15rem;
  /* background: #e7e7e7; */
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  /* color: #000;  */
  flex: 1;
}

#header_03 .header_topBar_wrap .header_banking_info .header_balance span,
#header_03 .header_topBar_wrap .header_bottom_row .header_balance span {
  font-weight: 600;
}

/* #header_03 .header_topBar_wrap .header_bottom_row .header_balance {
  margin-right: 2.35rem;
} */

#header_03 .header_topBar_wrap .header_banking_info .header_balance_currency,
#header_03 .header_topBar_wrap .header_bottom_row .header_balance_currency {
  margin-right: .25rem;
  text-transform: uppercase;
  min-width: 11rem;
  justify-content: space-between;
  display: flex;
}

#header_03 .header_topBar_wrap .header_banking_info .header_balance_amount,
#header_03 .header_topBar_wrap .header_bottom_row .header_balance_amount {
  min-width: 7rem;
  text-align: end;
  flex: 1;
}

#header_03 .header_topBar_wrap .header_banking_info .header_balance_icon svg {
  margin: 0 .5rem;
  cursor: pointer;
  font-size: 1.5rem;
}

#header_03 .header_topBar_wrap .header_banking_info .header_btnRestore,
#header_03 .header_topBar_wrap .header_banking_info .header_btnPromoClaim {
  display: unset;
  flex-direction: unset;
  align-items: unset;
  min-width: 6rem;
  padding: 0.35rem 1rem;
  margin-left: 0.25rem;
  white-space: nowrap;
  text-transform: uppercase;
  min-width: 7rem;
  font-weight: 600;
  white-space: nowrap;
  padding: 0.35rem;
  height: 2rem;
  font-size: .7rem;
  width: auto;
  /* margin-left: .35rem; */
  margin-right: -2px;
}

#header_03 .header_topBar_wrap .header_banking_info .header_bankinginfo_actionbtn button:first-of-type {
  margin-left: .5rem;
  margin-right: 0;

}

#header_03 .header_topBar_wrap .header_banking_menu {
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: row;
  justify-content: flex-end;
}

#header_03 .header_banking_menu_list {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 100%;
  flex: 1;

}

#header_03 .header_banking_menu_list div a {
  padding: 0 .25rem
}

#header_03 .header_point_token {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.header_point_token .header_point img,
.header_point_token .header_token img {
  width: 1.2rem;
  margin-right: .25rem;
}

/* #header_03 .header_topBar_wrap .header_noti {
  position: absolute;
  right: -2.5rem;
  display: flex;
  flex-direction: row;
} */

#header_03 .header_noti .noti_dot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0.9rem;
  height: 0.9rem;
  background: red;
  line-height: 1rem;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 50%;
}

#header_03 .header_topBar_wrap .header_noti img {
  /* width: 1.65rem; */
  width: 1.65rem;
}

#header_03 .header_topBar_wrap .header_noti svg {
  position: absolute;
  color: red;
  right: -0.5rem;
  top: -0.5rem;
}

#header_03 .header_topBar_wrap .header_top_icon {
  margin: 0 .28rem;
  width: 1.65rem;
}

/* -------  Desktop End ------- */

/* ------ Header Banking Info End ------- */


/* Game Wallet Modal */
#gameWalletModal.modal-dialog {
  margin: auto;
  width: 75%;
}

#gameWalletModal.modal-dialog .modal-content {
  background: transparent;
  border: 0;
}

#gameWalletModal .modal-header {
  /* border-bottom: 1px solid; */
  border: 0;
  /* background: red;
  margin: 0.8rem 0.8rem 0;

  color: #fff;
  position: relative; */
}

#gameWalletModal .modal-header .btn-close {
  position: relative;
  background: transparent;
  color: inherit;
  opacity: 1;
}

#gameWalletModal .modal-header .btn-close::after {
  position: absolute;
  content: 'x';
  color: inherit;
  font-size: 2rem;
  top: 46%;
  /* width: 2rem; */
  right: 1.25rem;
  transform: translate(0, -50%);
  color: #fff;
}

#gameWalletModal .modal-body {
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}

#gameWalletModal ul.game_wallet_modal {
  list-style-type: none;
  padding: 0;
}

#gameWalletModal ul.game_wallet_modal li {
  display: flex;
  justify-content: space-between;
  /* border-bottom: 1px solid #dee2e6; */
  padding: 0.5rem 1rem;
  background: #fff;
  color: #808080;
  margin: 0.5rem 0;
  border-radius: 0.5rem;
}


/* promo claim */
#promoClaimModal.modal-dialog {
  width: 30rem;
  margin: auto;
}

#promoClaimModal .promoClaim_content {
  border-bottom: 1px dashed #000;
  padding: 1rem 0 0;
}

#promoClaimModal .promoClaim_content:first-child {
  padding-top: 0
}

#promoClaimModal .promoClaim_content .promoClaim_content_row {
  padding: 0 0 1rem;
}

#promoClaimModal .promoClaim_content .promoClaim_content_row svg {
  color: red;
}

#promoClaimModal .promoClaim_content .promoClaim_content_row:last-child {
  display: flex;
  align-items: center;
  font-size: .95rem;
  justify-content: space-between;
}


#promoClaimModal label {
  font-weight: 500;
  /* color: var(--color-theme); */
}

/* Mobile to Desktop */
@media (min-width: 992px) {

  #header_03 .header_topBar {
    padding: 0;
    height: 6rem;
  }

  /* #header_03 .header_logo img {
    height: 3.5rem;
  } */

  #header_03 .header_btnLogin,
  #header_03 .header_btnRegister,
  #header_03 .header_btnLogout,
  #header_03 .header_btnBanking {
    text-transform: uppercase;
    min-width: 7rem;
    font-weight: 600;
    white-space: nowrap;
    padding: 0.35rem;
    height: 2rem;
    font-size: .7rem;
    width: auto;
    margin-left: .25rem;
  }

  #header_03 .header_noti .noti_dot {
    width: 0.55rem;
    height: 0.55rem;
  }

  /* BEFORE LOGIN HEADER */
  #header_menu_expand_lg.offcanvas.offcanvas-end {
    height: 100% !important;
    justify-content: flex-end;
    /* align-items: flex-end; */
  }

  #header_03 .header_btnRegister,
  #header_03 .header_btnRegister:hover,
  #header_03 .header_btnLogout,
  #header_03 .header_btnLogout:hover {
    margin-left: .35rem;
  }

  #header_03 .header_banking_info .header_btnRestore img,
  #header_03 .header_banking_info .header_btnPromoClaim img {
    width: 3.5rem;
  }

  #header_03 .btn_banking::after{
    content: '';
    position: absolute;
    bottom: -10px;
    width: 100%;
    left: 2px;
    background: transparent;
    height: 100%;
  }
}


/* ------------------- Header End --------------------- */

#multipleLoginDialog.modal-dialog {
  width: 75%;
  margin: auto;
}


/* HEADER TEMPLATE 2 */
/* DESKTOP */
.header_navigation_section {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.header_info_section {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-left: auto;
}

.header_top_row,
.header_bottom_row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 0 0 .5rem 0;
}

.header_top_row {
  padding: 0;
}

#header_03 .header_time {
  margin-right: .25rem;
  width: 11rem;
}

#header_03 .language_header {
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: row;
  height: 100%;
  min-width: 3rem;
}

#header_03 .language_header img {
  width: 1.75rem;
  margin: 0 0.25rem 0 0;
  filter: drop-shadow(1px 2px 3px rgb(0, 0, 0, .175));
}

#header_03 .language_header .header_current_language {
  margin-left: .5rem;
  margin-right: .5rem;
  font-weight: 600;
}

#header_03 .language_header .language_dropdown {
  display: none;
  position: absolute;
  top: 100%;
  z-index: 2;
  width: 100%;
  /* padding: 0; */
  right: 0;
  background: #fff;
  color: #000;
  width: 10rem;
  padding: 0.5rem 0;
  border: 1px solid rgb(0, 0, 0, .175);
  border-radius: 0.375rem;
}

#header_03 .language_header:hover .language_dropdown {
  display: block;
}

#header_03 .language_header:hover svg {
  transition: all .5s;
  transform: rotate(-180deg);
}

#header_03 .language_header .language_dropdown .header_menu_dropdownItem {
  padding: 0.25rem 1rem;
  background: transparent;
  color: #000;
}

#header_03 .language_header .language_dropdown .header_menu_dropdownItem:hover {
  background: #e9ecef;
}

#header_03 .language_header .language_dropdown ul li {
  padding: 0.5rem;
  background: #fff;
  cursor: pointer;
}

#header_03 .language_header .language_dropdown ul li:hover {
  /* background: transparent; */
  color: #000;
}


#header_03 .header_menu_dropdownItem {
  display: flex;
  justify-content: flex-start;
  align-items: center;

}


#header_03 .header_menu_dropdownItem .header_menu_icon {
  width: 1.8rem;
  margin: 0 0.8rem 0 0;
}



#header_03 .header_menu_dropdownItem .header_text {
  font-weight: 600;
}

#header_03 .header_menu_page {
  margin: 0;
  height: 100%;
}

/* #header_03 .header_menu_icon {
  width: 1.5rem;
  margin: auto;
} */

.header_menu_icon {
  width: 25px;
  height: 25px;
  margin-right: .25rem;
  background-image: var(--menu-icon);
  background-size: cover;
}

.header_menu_content.active .header_menu_icon {
  background-image: var(--active-menu-icon);
}

#header_03 .header_menu_page:hover .header_menu_icon {
  background-image: var(--active-menu-icon);
}

#header_03 .btn_banking,
#header_03 .btn_claim {
  position: relative;
}

#header_03 .btn_banking .banking_dropdown,
#header_03 .btn_claim .claim_dropdown {
  display: none;
  position: absolute;
  list-style: none;
  /* padding: 0.15rem .6rem; */
  z-index: 5;
  /* transform: translate(-50%, 0); */
  left: 50%;
 
  /* margin-top: .5rem; */
  border-radius: .35rem;
}

#header_03 .btn_banking .banking_dropdown:after,
#header_03 .btn_claim .claim_dropdown:after {
  content: " ";
  position: absolute;
  left: 52%;
  top: -7px;
  border-top: none;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  transform: translate(-50%, 0);
}

#header_03 .btn_claim .claim_dropdown:after {
  left: 50%;
}


#header_03 .banking_dropdown li,
#header_03 .claim_dropdown li {
  /* margin: 0.5rem 0; */
  display: flex;
  align-items: center;
  /* border-radius: .5rem; */
  /* justify-content: space-between; */
  flex-direction: row;
  cursor: pointer;
}

.header_WalletIcon {
  /* width: 25px;
  height: 25px;
  margin-right: .25rem; */
  background-image: var(--wallet-icon);
  background-size: cover;
}

.header_WalletIcon.active {
  background-image: var(--active-wallet-icon);

}

#header_03 .banking_dropdown a li:hover .header_WalletIcon,
#header_03 .claim_dropdown li:hover .header_WalletIcon {
  background-image: var(--active-wallet-icon);
}

#header_03 .banking_dropdown li img,
#header_03 .claim_dropdown li img {
  width: 2rem;
  margin-right: .5rem;
}

/* #header_03 .banking_dropdown li {
  background: #fff;
  color: #808080;
} */

#header_03 .banking_dropdown li.main_wallet,
#header_03 .claim_dropdown li.main_wallet {
  justify-content: space-between;
  margin-bottom: .5rem;
  font-size: .9rem;
}

#header_03 .banking_dropdown li.main_wallet div:nth-child(2),
#header_03 .claim_dropdown li.main_wallet div:nth-child(2) {
  display: flex;
  align-items: center;
  flex-direction: row;
}

#header_03 .banking_dropdown li:not(.main_wallet) div:nth-child(2),
#header_03 .claim_dropdown li:not(.main_wallet) div:nth-child(2) {
  margin-right: 1.5rem;
}

#header_03 .btn_banking:hover .banking_dropdown,
#header_03 .btn_claim:hover .claim_dropdown {
  display: block;
}

.header_top_row img {
  width: 1.75rem;
  /* margin: 0 0.28rem; */
  height: auto;
}

#header_03 .header_noti {
  position: relative;
  /* margin-right: 1.5rem; */
}

.popover-body.icon_name {
  padding: 0.25rem;
  font-size: .85rem
}

#header_03 .btn_gamewallet {
  position: relative;
  /* display: flex; */
}

#header_03 .btn_gamewallet .game_wallet_dropdown {
  display: none;
  position: absolute;
  list-style: none;
  padding: 0.5rem 1rem;
  z-index: 5;
  transform: translate(-50%, 0);
  left: 50%;
  width: 20rem;
  font-size: .8rem;
  margin-top: .5rem;
  border-radius: .35rem;
  /* height: 30rem;
  overflow: auto; */

}

#header_03 .btn_gamewallet .game_wallet_dropdown_body {
  /* height: 25rem; */
  height: auto;
  overflow: auto;
}

#header_03 .btn_gamewallet .game_wallet_dropdown:after {
  content: " ";
  position: absolute;
  left: 50%;
  top: -7px;
  border-top: none;
  border-right: 10px solid transparent;
  border-left: 10px solid transparent;
  transform: translate(-50%, 0);
}

/* #header_03 .game_wallet_dropdown tr td {
  margin: 0.3rem 0;
  display: flex;
  align-items: center;
  padding: 0.5rem .8rem;
  border-radius: .5rem;
  justify-content: space-between;
  flex-direction: row;

} */
#header_03 .game_wallet_dropdown table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 .5rem;
}

#header_03 .game_wallet_dropdown table tr th:nth-child(3),
#header_03 .game_wallet_dropdown table tr th:nth-child(4) {
  width: 35px;
  font-size: .65rem;
  text-align: center;
  line-height: normal;
}

#header_03 .game_wallet_dropdown table tr td:nth-child(3),
#header_03 .game_wallet_dropdown table tr td:nth-child(4) {
  text-align: center;
  padding: 0
}

#header_03 .game_wallet_dropdown table tr th:nth-child(4){
padding-right: 7px;

}

#header_03 .game_wallet_dropdown table tr td:nth-child(2){
text-align: end;
}

#header_03 .gameWallet_accordion .accordion-button div:nth-child(2){
  min-width: 5rem;
    text-align: end;
}

#header_03 .gameWallet_accordion .accordion-button div:nth-child(3){
  width: 40%;

}

#header_03 .game_wallet_dropdown table tbody tr {
  margin: 0.3rem 0;
  margin-bottom: .5rem;
  padding: 0.5rem .8rem;
  border-radius: .5rem;
}

#header_03 .game_wallet_dropdown table tbody tr td:first-child {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

#header_03 .game_wallet_dropdown table tbody tr td:last-child {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
  padding-right: 10px;
}

#header_03 .game_wallet_dropdown table tr td {
  padding: 0.5rem .8rem;
}

#header_03 .game_wallet_dropdown div.main_wallet {
  margin: 0.3rem 0;
  display: flex;
  align-items: center;
  padding: 0.5rem .8rem;
  border-radius: .5rem;
  justify-content: space-between;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: .5rem;
  font-size: .9rem;
}

#header_03 .game_wallet_dropdown li.main_wallet div:nth-child(2) {
  display: flex;
  align-items: center;
  flex-direction: row;
}

#header_03 .game_wallet_dropdown li:not(.main_wallet) div:nth-child(2) {
  margin-right: 1.5rem;
}

#header_03 .btn_gamewallet:hover .game_wallet_dropdown {
  display: block;
}


/* MOBILE*/
#header_03 .header_banking_info .header_btnRestore img,
#header_03 .header_banking_info .header_btnPromoClaim img {
  height: 2.5rem;
}


#gameWalletModal .game_wallet_dropdown table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 .5rem;
}

#gameWalletModal .game_wallet_dropdown table tr th:nth-child(3),
#gameWalletModal .game_wallet_dropdown table tr th:nth-child(4) {
  width: 30px;
  font-size: .9rem;
  text-align: center;
  line-height: normal
}

#gameWalletModal .game_wallet_dropdown table tr td:nth-child(3),
#gameWalletModal .game_wallet_dropdown table tr td:nth-child(4) {
  text-align: center;
  padding: 0
}


#gameWalletModal .game_wallet_dropdown table tbody tr {
  background: #fff;
  color: #808080;
  margin: 0.3rem 0;
  margin-bottom: .5rem;
  padding: 0.5rem .8rem;
  border-radius: .5rem;
}

#gameWalletModal .game_wallet_dropdown table tbody tr td:first-child {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

#gameWalletModal .game_wallet_dropdown table tbody tr td:last-child {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem;
}

#gameWalletModal .game_wallet_dropdown table tr td {
  padding: 0.75rem .8rem;
}

#gameWalletModal .game_wallet_dropdown div.main_wallet {
  margin: 0.3rem 0;
  display: flex;
  align-items: center;
  padding: 0.75rem .8rem;
  border-radius: .5rem;
  justify-content: space-between;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: .5rem;
  font-size: 1.25rem;
}

#gameWalletModal .game_wallet_dropdown li.main_wallet div:nth-child(2) {
  display: flex;
  align-items: center;
  flex-direction: row;
}

#gameWalletModal .game_wallet_dropdown li:not(.main_wallet) div:nth-child(2) {
  margin-right: 1.5rem;
}

#gameWalletModal img {
  width: 2rem;
}

#header_03 .more_menu {
  position: relative;
  padding: 0.25rem 0;
  cursor: default;
  cursor: pointer;
}


#header_03 .dropdown_more_content_container a {
  /* width: 15%; */
  display: flex;
  flex-direction: column;
  /* padding: 0 0.5rem 1rem; */
  font-size: .7rem;
  text-decoration: none;
  /* filter: brightness(0.5); */
  width: 100%;
}

#header_03 .dropdown_more_content_container a:hover {
  filter: brightness(1);
}

#header_03 .dropdown_more_content_wrap {
  max-height: 65vh;
  overflow-y: auto;
}

#header_03 .dropdown_more_content_wrap a img {
  width: 7.643rem;
  margin: auto;
}



#header_03 .dropdown_more_content .dropdown_more_content_wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  /* margin: 0 1.5rem; */
  flex-direction: column;
}

/* .dropdown_content a {
  width: 20%;
} */
/*   
.dropdown_content a img {
  width: 12rem;
} */


#header_03 .more_menu .dropdown_more_content {
  display: none;
  position: absolute;
  min-width: 10rem;
  left: 0%;
  z-index: 2;
  top: 100%;
  padding: .5rem 0;
  transform: translate(0%, 0);
}

#header_03 .more_menu:hover .dropdown_more_content {
  display: block;
}


/* .header_sideBar .btn-close {
  color: inherit;
  opacity: 1;
  font-size: 1.5rem;
  background: transparent;
  position: relative;
} */

.header_sideBar .btn-close svg {
  position: absolute;
  top: 50%;
  /* padding: 0.5rem; */
  right: 50%;
  transform: translate(50%, -50%);
}


#header_language {
  width: 2.5rem;
  margin-left: 0.5rem;
  cursor: pointer;
}

#header_language img {
  filter: drop-shadow(1px 2px 3px rgb(0, 0, 0, .175));
}

.language_content img {
  width: 3.5rem;
  filter: drop-shadow(1px 2px 3px rgb(0, 0, 0, .175));
}

.language_content .dropdown-item {
  margin: 1rem 0;
}

#languageModal.modal-dialog {
  width: 25rem;
  margin: auto;
}

#languageModal .modal-body {
  padding-top: 0;
}

.testMode {
  background: red;
  color: #fff
}

@media (prefers-color-scheme: dark) {
  .testMode {
    background: green;
    color: #000
  }
}

.coming_soon {
  opacity: .5;
  pointer-events: none;
}


.designerMode a {
  pointer-events: none;
}

.coupon_balance.header_coupon {
  position: relative;
}

.coupon_balance.header_coupon .noti_dot {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: auto;
  height: auto;
  border-radius: 0.75rem;
  padding: 0.15rem 0.45rem;
  top: -8px;
  right: -8px;
  background: red;
  line-height: 1rem;
  position: absolute;
  font-size: .6rem;
  outline: 1px solid #fff;
}

.header_bankinginfo_actionbtn button.header_coupon {
  position: relative;
}

.header_bankinginfo_actionbtn .header_coupon .noti_dot {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: auto;
  height: auto;
  border-radius: 0.75rem;
  padding: 0.25rem 0.5rem;
  top: -5px;
  right: -5px;
  background: red;
  line-height: 1rem;
  position: absolute;
  outline: 1px solid #fff;

}


.dropdownHoverImg {
  width: 100px;
  height: 100px;
  margin-right: .25rem;
  background-image: var(--dropdown-image);
  background-size: cover;
}

.header_WalletIcon.active {
  background-image: var(--active-wallet-icon);

}

#header_03 .dropdown_content_container a:hover .dropdownHoverImg {
  background-image: var(--active-dropdown-image);
}


@media (min-width: 450px) {

  #header_03 .header_banking_info .header_banking_info_left {
    width: 48%;
  }
}

#header_03 .header_point{
  display: flex;
  align-items: center;
  margin-top: .25rem;
}

#header_03 .header_point img{
 height: 1.75rem;
 margin-right: .25rem;
}

#header_03 .header_point span:nth-child(1){
text-transform: uppercase;
font-weight: 600;
margin-right: .25rem;
}

#header_03 .header_point span:nth-child(2){
  margin-left: .25rem;
  font-weight: 600;
    /* color: #666; */
  }

  #header_03 .header_point svg{
    margin-left: .5rem;
    font-size: 1.5rem;
    margin-top: .25rem;
    color: red;
    }


    #languageModal .header_menu_dropdownItem{
      background: transparent;
      color: #000;
    }

#header_03 .comingSoonMenu .header_menu_content {
  opacity: .5;
  border: 0;
  color: inherit;
}
