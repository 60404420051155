#promoClaimModal .modal-body {
    max-height: 32rem;
    height: auto;
    overflow: auto;
}

#promoClaimModal .no_record_img {
    width: 10rem;
    margin: 0.5rem;
}

.claim_filterTab {
    margin: 0
}

.claim_filterTab .nav-item {
    width: 50%;
}
#promoClaimModal .filterTab{
    margin: 0;
}
#promoClaimModal .filterTab .filterTab_selection{
    margin: 0;
}





.btnCancelPromotion{
    padding: 0.5rem 1.5rem;
    line-height: normal;
}
/* #promoClaimModal .filterTab .filterTab_selection.active {
    background: red;
    color: #fff;
} */

.promoClaimBackdrop+div[role="dialog"]{
    z-index: 1050;
}


.cancelPromotionBackdrop+div[role="dialog"]{
    z-index: 1055;
}

