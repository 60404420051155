.section_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: end;
}

.section_title {
    margin-bottom: .5rem;
    /* text-transform: uppercase; */
    display: flex;
    align-items: center;
    /* font-weight: 600; */
    text-align: left;
    white-space: nowrap;
}

/* .section_title img {
    max-width: 2.5rem;
    margin-right: 0.5rem;
    width: auto;
    height: 2rem;
} */

.section_details {
    margin-left: .25rem;
    margin-right: .25rem;
    text-decoration: none;
}

.dynamic_template_2 .section_header{
    padding-top:1.5rem
}
/* ----------- -Include Content- ----------- */

/* flex row */
.flex-row .card-img-top {
    width: 50%;
}

@media (min-width: 576px) {
    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-right: .5rem;
    }
}

.dynamic_template_2 .swiper-pagination{
    position: relative;
    transform: translateZ(15px);
}


.card_wrap img{
    border-radius: inherit;
}