#contactUs_01{
  display: flex;
}

#contactUs_01 .contact_container {
  min-height: 70vh;
  padding: 0 1.5rem 1.5rem;
}

.contactUs_body{
  width: 100%;
}

/* #contactUs_01 .contact_card.card { */
  /* background: transparent;
  padding: 0 1rem;
  margin-bottom: 0rem;
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #808080; */
/* } */

#contactUs_01 .contact_card img {
  width: 4rem;
  margin-right: 1.5rem;
}

#contactUs_01 .contact_card .card-body {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

#contactImgDialog.modal-dialog {
  max-width: 32rem;
  margin: auto;

}

#contactImgDialog .modal-body {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.5rem;
  min-height: 20rem;
}


#contactImgDialog .modal-header {
  font-size: 1.25rem;

}

#contactUs_01 .contact_card_content {
  display: flex;
  flex-direction: column;
  align-items: start;
}

#contactUs_01 .contact_card_content img {
  cursor: pointer;
  margin: 0;
  width: 6rem;
}


@media (min-width: 992px) {
  #contactUs_01 .contact_container {
    padding: 0
  }

  #contactUs_01 .contact_container_row {
    justify-content: center;
  }

  #contactUs_01 .contact_card.card {
    /* min-height: 25rem; */
    /* background: #ffffff;
    color: #000000;
    border: 1px solid #c9c9c9;
    border-radius: 0.8rem;
    padding: 0; */
    /* flex: 1; */
    position: relative;
    width: 23%;
    padding-top: 21%;
    /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
    /* color: #000000;
    border: 1px solid #c9c9c9;
    margin: 10px;
    background-color: white;
    border-radius: 0.8rem;
    padding: 1rem; */
  }


  #contactUs_01 .contact_card .card-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  #contactUs_01 .contact_card img {
    margin-bottom: 1.25rem;
    margin-right: 0;
  }

  #contactUs_01 .contact_card .card-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #contactUs_01 .contact_card .card-title+img {
    margin: 1rem 0 0;
    width: 5rem;
  }

  #contactImgDialog.modal-dialog {
    max-width: 15%;
    margin: auto
  }

  #contactUs_01 .contact_card_content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

}

#contactUs_01 .card_Livechat {
  cursor: pointer;
}


/* #contactImgDialog img {
  max-width: 50%;
} */