.section_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: end;
}

.section_title {
  margin-bottom: .5rem;
  /* text-transform: uppercase; */
  display: flex;
  align-items: center;
  /* font-weight: 600; */
}

/* .section_title img {
  max-width: 2.5rem;
  margin-right: 0.5rem;
  width: auto;
  height: 2rem;
} */

.section_details {
  margin-left: .25rem;
  margin-right: .25rem;
  text-decoration: none;
}

/* ----------- -Include Content- ----------- */

/* flex row */
.flex-row .card-img-top {
  width: 50%;
}

@media (min-width: 576px) {
  .card-group>.card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: .5rem;
  }
}

/* data promotion content */

.promotion_btn {
  width: 100%;
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
}

.promotion_btnInfo,
.promotion_btnJoin {
  width: 49%;
  padding: 0.5rem;
  text-transform: uppercase;
}

/* data game */
.game_name {
  text-align: center;
  font-size: .85rem;
  padding: 0.5rem 0
}

/* FILTER TAB */
.filterTab {
  margin: 1.5rem 0rem;
  text-align: center;
  padding: .25rem;
  display: flex;
  flex-wrap: unset;
  overflow-x: auto;
  font-size: 1rem;
  width: 100%;
}

.filterTab_selection {
  white-space: nowrap;
  text-transform: capitalize;
  margin-right: 1rem;
  padding: 0.8rem 1rem;
}




/* ------------------------------------- */
.layout_body {
  padding: 0 0 1.5rem 0;
  display: flex;
  flex-direction: column;
}

.layout_body .row {
  /* justify-content: center; */
}

/* .dynamic_template_1.section{
  min-height: 65vh;
} */
.dynamic_template_1 .section_header {
  padding-top: 1.5rem;
}

/* default */
#coupon .card_container {
  padding: .8rem .5rem;
  position: relative;
  margin: unset;
  min-height: 11rem;
}

#coupon .card_wrap {
  /* border: 1px solid; */
  display: flex;
  flex-direction: column;
  overflow: visible;
  cursor: pointer;
  transition: all .45s;
  /* margin: 1rem 0 */
}


#coupon .card_wrap:not(.card_wrap.card_image_1x3):hover {
  transform: translate(0, -0.75rem);
}

.card_wrap img {
  border-radius: inherit;
  width: 100%;
}

.card_image_wrap,
.card_content_title,
.card_content_desc {
  margin: 0
}

.card_image_wrap {
  position: relative;
  /* overflow: hidden; */
  flex: 1;
}

.content_container {
  padding: 0.5rem;
  /* border-top: 1px solid; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card_action_wrap {
  width: 100%;
  display: flex;
}

.card_action_bottom .card_action_wrap {
  flex-direction: column-reverse;
  /* margin:.5rem 0rem */
}

.card_content_title {
  font-size: 1.35rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 10rem;
}

.card_content_desc {
  font-size: .85rem;
}

.card_action_wrap .button_action {
  width: 50%;
  padding: .35rem;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: .25rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: pre;
}

.button_action_part {
  width: 100%;
  display: flex;
}

.button_action1 {
  margin: 0 .25rem 0 0;
}

.button_action2 {
  margin: 0 0 0 .25rem;
}

.promotionTime_part .promoDetails_timeShow_container {
  justify-content: end;
}

.promotionTime_part .count_box p:first-of-type {
  min-width: 3.5rem;
  width: auto;
}

.promotionCode_part {
  position: relative;
  margin: 1rem 0;
  display: flex;
  align-items: stretch;
  width: 49%;
  margin-left: auto;
  border: 1px solid;
}

.promotionCode_part label {
  font-size: .85rem;
  position: relative;
  /* margin-top: -1.25rem; */
  left: 0;
  /* white-space: pre; */
  width: 49%;
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-right: 1px solid; */
  background: transparent;
  color: #000;
  font-weight: bold;
}

.promotionCode_part .promotionCode_content {
  display: flex;
  align-items: center;
  /* border-radius: 0.35rem; */
  padding: 0.5rem;
  font-size: .85rem;
  justify-content: space-between;
  width: 52%;
}

.promotionCode_part .promotionCode_content p {
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: .85rem;
  text-align: center;
}


/* action hover */
.card_container .card_action_hover .card_action_wrap {
  transition: opacity .3s, -webkit-transform .3s;
  transition: transform .3s, opacity .3s;
  transition: transform .3s, opacity .3s, -webkit-transform .3s;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
  -webkit-transform: translateY(20%);
  transform: translateY(20%);
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 101%;
  background: rgba(0, 0, 0, .5);
  -webkit-flex-direction: column;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: inherit;
}

.card_action_hover:hover {
  transform: translateY(-0.11rem);
}

.card_action_hover:hover .card_action_wrap {
  transform: translateY(0);
  opacity: 1;
}

.card_container .card_action_hover .card_action_wrap button {
  margin: 0.25rem 0;
  padding: .35rem 0;
  min-width: 8rem;
}


/* content position right */

.card_position_right {
  display: flex;
  flex-direction: row;
  width: 100%;
}

/* image 1x1 */
.card_image_1x1.card_position_right .card_image_wrap {
  width: 50%;
}

.card_image_1x1.card_position_right .content_container {
  width: 50%;
}


/* image 1x2 */
.card_image_1x2.card_position_right .card_image_wrap {
  width: 60%;
}

.card_image_1x2.card_position_right .content_container {
  width: 40%;
}

/* image 1x3 */
.card_image_1x3.card_position_right .card_image_wrap {
  width: 60%;
}

.card_image_1x3.card_position_right .content_container {
  width: 40%;
  padding: .5rem 1rem;
  padding-bottom: 0;
}

.card_image_1x3.card_position_right .card_content_title {
  font-size: 1.25rem;
}



.card_image_1x3.card_position_right .button_action {
  padding: .55rem;
}


/* content position bottom */

/* image 2x1 */


.card_image_2x1.card_position_bottom .content_container {
  text-align: center;
}

.card_image_2x1.card_position_bottom .card_content_title {
  overflow: visible;
  width: auto;
}

.type_promotion .promotionCode_part svg {
  font-size: 1.25rem;
}

.type_promotion .card_content_desc {
  font-size: 1rem;
}

.type_promotion .card_content_desc_container {
  display: flex;
}


@media (min-width: 992px) {
  .promotion_btn {
    width: 50%;
    margin-top: 0.75rem;
    display: flex;
    justify-content: space-between;
  }

  .promotion_btnInfo,
  .promotion_btnJoin {
    width: 49%;
    padding: 0.5rem;
    text-transform: uppercase;
  }

  .template_cardbody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .card_content_title {
    font-size: .9rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 10rem;
  }

  .card_image_1x3.card_position_right.type_promotion .card_content_title,
  .card_image_1x3.card_position_right.type_promotions .card_content_title {
    font-size: 1.25rem;
    width: auto;
    text-transform: uppercase;
    color: var(--color-theme);
    font-weight: 600;
  }

  .card_image_1x3.card_position_right.type_promotion .card_content_desc_container,
  .card_image_1x3.card_position_right.type_promotions .card_content_desc_container {
    justify-content: flex-end;
  }

  .card_image_1x3.card_position_right.type_promotion .button_action_part,
  .card_image_1x3.card_position_right.type_promotions .button_action_part {
    width: 55%;
    display: flex;
  }

  .card_image_1x3.card_position_right.type_promotion .promotionCode_part,
  .card_image_1x3.card_position_right.type_promotions .promotionCode_part {
    width: 45%;
    border-style: solid;
    border-width: 2px;
  }

  .type_promotion .card_content_desc {
    font-size: 1rem;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    word-break: break-word;
    max-height: 3rem;
    padding-right: 10px;
    width: 50%;
  }


}


@media (min-width: 992px) {
  .card_container .card_action_hover .card_action_wrap button {
    font-size: 1rem;
  }

  .card_action_bottom .card_action_wrap {
    justify-content: space-between;
    flex-direction: row;
    margin: 0
  }

  .card_action_bottom .card_action_wrap .button_action {
    min-width: 35%;
    width: auto;
  }

  .promotionCode_part {
    position: relative;
    margin: 0;
  }

  /* .promotionCode_part label {
      position: absolute;
  } */
}

@media (max-width: 992px) {
  .button_action {
    font-size: 1.25rem;
  }

  .card_image_1x3.card_position_bottom.type_promotion .card_content_title,
  .card_image_1x3.card_position_bottom.type_promotions .card_content_title {
    font-size: 1.25rem;
    width: auto;
    text-transform: uppercase;
    color: var(--color-theme);
    font-weight: 600;
  }

  #coupon .card_container {
    margin: auto;
  }

  .filterTab {
  font-size: 1.25rem;  }
}

.Section_promoContainer_body {
  min-height: 100vh;
}


.promotionTag_part {
  margin-bottom: .5rem;
  font-size: .85rem;
}


/* Inside Image */

.card_position_insideImage figure::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to top, black, transparent, transparent);
  z-index: 1;
  border-radius: inherit;
}

.card_position_insideImage figcaption {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff;
  z-index: 2;
  padding: .5rem;
}

.card_action_hover:hover figure::before,
.card_action_hover:hover figcaption {
  opacity: 0;
}

.lazy-load-image-background.blur.lazy-load-image-loaded {
  height: 100%;
  width: 100%;
}

/* --------------------------------------- */

.coupon_body {
  min-height: 100vh;
}


.card_coupon_active {
  /* background-image: ; */
}


.coupon_content_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  height: 100%;
  text-transform: uppercase;
}

.coupon_header_container {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  font-size: 2rem;
  line-height: normal;
}

.coupon_body_container {
  width: 40%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  padding: 2rem;
  font-size: .95rem;
}

.stamp_coupon {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}

.button_couponClaim {
  line-height: normal;
  width: 60%;

}

.card_coupon_expired,
.card_coupon_used {
  opacity: .5;
}

.coupon_title {
  height: 3.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  flex: 1;
}

.coupon_tnc {
  /* display: flex;
  
  justify-content: end;
  align-items: end; */
  line-height: normal;
  cursor: pointer;

}

.coupon_expireTime {
  /* font-size: .85rem; */
  white-space: nowrap;
}

.coupon_claimInfo_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button_claimCoupon {
  width: 100%
}

.button_claimCoupon:disabled{
  opacity: .5;
}

#couponDetailModal .modal-body,
#claimCouponModal .modal-body {
  max-height: 32rem;
  height: auto;
  overflow: auto;
}

#couponDetailModal .no_record_img,
#claimCouponModal .no_record_img {
  width: 10rem;
  margin: 0.5rem;
}

#couponDetailModal.modal-dialog,
#claimCouponModal.modal-dialog {
  width: 30rem;
  margin: auto;
}

#claimCouponModal .promoClaim_content {
  border-bottom: 1px dashed #000;
  padding: 1rem 0 0;
}

#couponDetailModal .promoClaim_content:first-child,
#claimCouponModal .promoClaim_content:first-child {
  padding-top: 0
}

#couponDetailModal .promoClaim_content .promoClaim_content_row,
#claimCouponModal .promoClaim_content .promoClaim_content_row {
  padding: 0 0 1rem;
}

#couponDetailModal .promoClaim_content .promoClaim_content_row svg,
#claimCouponModal .promoClaim_content .promoClaim_content_row svg {
  color: red;
}

/* #couponDetailModal .promoClaim_content .promoClaim_content_row:last-child,
#claimCouponModal .promoClaim_content .promoClaim_content_row:last-child {
  display: flex;
  align-items: center;
  font-size: .95rem;
}
 */

#couponDetailModal label,
#claimCouponModal label {
  font-weight: 500;
  /* color: var(--color-theme); */
}
#claimCouponModal .form_required_input {
 color:red;
  /* color: var(--color-theme); */
}

/* ----------------------------------- */

/* .coupon_body {
  background: rgba(231, 231, 231, 1);
}


.coupon_content_wrapper {
  color: #fff;
}

.coupon_body_container {
  color: #000;
}

.button_couponClaim {
  padding: 0.5rem 0.5rem;
  border-radius: 5px;
}

.coupon_title {
  margin: 1rem 0;
  font-weight: 600;
}

.coupon_expireTime {
  margin-bottom: 1rem;
  color: red;
}

.coupon_tnc {
  color: #0d6efd;
}


#coupon .filterTab {
  background: #fff;
  border-radius: 5px 5px 5px 5px;
}

#coupon .filterTab .filterTab_selection {
  border: 0;
  border-radius: 5px 5px 5px 5px;
}

#coupon .filterTab .filterTab_selection.active {
  background: red;
  color: #fff;
}

.button_claimCoupon {
  padding: 0.5rem;
  margin-top: .75rem;
  border-radius: 5px;
} */



/* ------------ add coupon style header ---------------*/

/* .claim_dropdown li {
  background: #fff;
  color: #000;
}


.claim_dropdown li:hover {
  color: red;
} */

