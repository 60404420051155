#wallet_01 .wallet_body {
  padding: 1.5rem 0
}

#wallet_01 .wallet_body_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

/* -----------  Wallet Bar --------------- */
#wallet_01 .walletBar {
  /* background: #ffffff;
  color: #000000; */
  /* border: 1px solid #000000; */
  border-radius: .5rem;
  padding: 2rem 1.5rem;
  width: 21%;
  flex: 0 0 21%;
  max-width: 21%;
  height: 100%;
}


#wallet_01 .walletBar .nav-item {
  width: 100%;
}

#wallet_01 .walletBar_selection {
  display: flex;
  align-items: center;
  margin-bottom: .5rem;
  font-size: 1rem;
  flex-direction: column;
}


#wallet_01 .walletBar_title {
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
  border-top: 1px solid #c8c5c5;
  padding-top: 1rem;
  font-weight: 600;
}

/* -----------  Wallet Bar (User Info) --------------- */

#wallet_01 .walletBar_Info {
  width: 100%;
}

#wallet_01 .walletBar_userInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
}

#wallet_01 .walletBar_userInfo img {
  height: 4.5rem;
  margin-right: 1rem;
}

#wallet_01 .walletBar_userInfo span:nth-child(1) {
  text-transform: uppercase;
}

#wallet_01 .walletBar_userName {
  /* color: var(--color-primary); */
}

#wallet_01 .form-label {
  font-size: 1.3rem;
  white-space: nowrap;
  width: 100%;
}

#dobEditModal .form-label,
#genderEditModal .form-label {
  display: flex;
}

#dobEditModal input[type="submit"],
#genderEditModal input[type="submit"],
#bankingModal input[type="button"] {
  width: 100%;
  padding: .5rem;
  margin-top: 1rem;
}

#dobEditModal.modal-dialog,
#genderEditModal.modal-dialog,
#bankingModal.modal-dialog {
  width: 30rem;
  margin: auto;
}

#dobEditModal .reminder_container,
#genderEditModal .reminder_container,
#bankingModal .reminder_container {
  padding: 1rem 0;
}

#dobEditModal .reminder_title,
#genderEditModal .reminder_title,
#bankingModal .reminder_title {
  color: red
}

#dobEditModal .reminder_content,
#genderEditModal .reminder_content,
#bankingModal .reminder_content {
  white-space: pre-line;
}

/* -----------  Wallet Bar (User QR) --------------- */

#wallet_01 .walletBar_walletUserQrInfo {
  padding: 1rem;
  /* background: #e7e7e7; */
  border-radius: 1rem;
  margin: .75rem 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

#wallet_01 .walletBar_walletUserQrUrl {
  word-break: break-all;
  margin-left: 1rem;
  font-size: .9rem;
}

/* .shareLink_button{
  padding: 0.5rem 1rem;
  border-radius: 25px;
  font-size: .8rem;
} */

/* -----------  Wallet Bar (Wallet Info) --------------- */

#wallet_01 .walletBar_walletInfo {
  padding: 1rem;
  background: #e7e7e7;
  border-radius: 1rem;
  margin: .75rem 0;
}

#wallet_01 .walletBar_walletTitle {
  text-transform: uppercase;
  font-weight: 600;
}

#wallet_01 .walletBar_walletBalance {
  text-transform: uppercase;
  padding-bottom: 0.5rem;
  display: flex;
}

#wallet_01 .walletBar_walletActionButton {
  display: flex;
  flex-direction: row;
}

#wallet_01 .walletBar_walletActionButton button:first-child {
  margin-right: .25rem;
}

#wallet_01 .walletBar_btnRestore,
#wallet_01 .walletBar_btnPromoClaim {
  text-transform: uppercase;
  /* font-weight: 00; */
  white-space: nowrap;
  padding: 0.15rem 0.5rem 0.15rem 0;
  display: flex;
  align-items: center;
  line-height: normal;
  font-size: .6rem;
  flex: 1;
  justify-content: center;
}

#wallet_01 .walletBar_btnLogout {
  text-transform: uppercase;
  /* font-weight: 00; */
  white-space: nowrap;
  /* padding: 0.15rem 0.5rem 0.15rem 0; */
  display: flex;
  align-items: center;
  line-height: normal;
  flex: 1;
  justify-content: center;
}

#wallet_01 .walletBar_btnRestore img,
#wallet_01 .walletBar_btnPromoClaim img,
#wallet_01 .walletBar_btnLogout img {
  width: 1.5rem;
}

#wallet_01 .walletBar_btnLogout {
  margin-top: 1rem;
  /* padding: 0.5rem 0; */
}


/* -----------  Wallet Bar (Auto Transfer) --------------- */

#wallet_01 .walletBar_autoTransfer {
  padding: 0.5rem;
}

#wallet_01 .walletBar_autoTransfer .form-check {
  display: flex;
  min-height: 1.5rem;
  padding-left: 1.5em;
  /* margin-bottom: 0.125rem; */
  align-items: center;
  justify-content: space-between;
}

#wallet_01 .walletBar_autoTransfer .form-check-label {
  text-transform: uppercase;
}

#wallet_01 .walletBar_autoTransfer .form-switch {
  padding-left: 0
}

#wallet_01 .walletBar_autoTransfer .form-check .form-check-input {
  float: right;
}

#wallet_01 .walletBar_autoTransfer .form-check-input {
  width: 3rem;
  height: 1.5rem;
  cursor: pointer;
}

#wallet_01 .walletBar_autoTransfer .form-check-input.is-invalid:focus,
#wallet_01 .walletBar_autoTransfer .was-validated .form-check-input:invalid:focus {
  box-shadow: none;
}

#wallet_01 .walletBar_autoTransfer .form-switch .form-check-input:checked {
  background-position: right center;
  /* background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e'), linear-gradient(to right, #5cf25e, #00c225) !important; */
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e'), linear-gradient(to right, #20BF55, #20BF55) !important;

}

#wallet_01 .walletBar_autoTransfer .form-check-input.is-invalid,
#wallet_01 .walletBar_autoTransfer .was-validated .form-check-input:invalid {
  border-color: #808080;
}



/* -----------  Wallet Bar (User Verification) --------------- */
#wallet_01 .walletBar_userVerification {
  padding: 1rem;
  /* background: #e7e7e7; */
  border-radius: 1rem;
  margin: .75rem 0;
}

#wallet_01 .walletBar_userVerification_title {
  text-transform: uppercase;
  padding: 0 0 0.25rem;
}

#wallet_01 .verify_icon.validated {
  /* background: #07e411; */
  background: #20BF55;
}

#wallet_01 .verify_icon {
  height: 2rem;
  width: 2rem;
  background: #bbb;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: .5rem;
}


/* -----------  Wallet Bar (User Verification) --------------- */
#wallet_01 .walletBar_point {
  padding: 1rem;
  /* background: #e7e7e7; */
  border-radius: 1rem;
  margin: .75rem 0;
}

#wallet_01 .walletBar_point_title {
  text-transform: uppercase;
  padding: 0 0 0.25rem;
}

#wallet_01 .walletBar_point span {
  margin-right: .25rem;
}


/* -----------  Wallet Container --------------- */

#wallet_01 .wallet_container {
  width: 100%;
  padding: 0;
}

#wallet_01 .wallet_container_m .myAccount_wrap,
#wallet_01 .wallet_container_m .notification_body {
  min-height: 65vh;
}

#wallet_01 .wallet_container_m .wallet_reminder_container {
  padding: 1rem 0
}

#wallet_01 .wallet_container_title {
  text-transform: uppercase;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding: 1rem 1.5rem;
  position: relative;
}

#wallet_01 .wallet_container_wrap {
  padding: 0 1.5rem 1.5rem;
  min-height: 100vh;
}

#wallet_01 .myFriend_body .wallet_container_wrap {
  padding: 0 0 1.5rem;
  min-height: 100vh;
}

#wallet_01 .wallet_btnSubmit {
  width: 100%;
  padding: 0.65rem;
  margin-top: 0.5rem;
  text-transform: capitalize;
}

#wallet_01 .wallet_reminder_container {
  padding: 1rem;
}

#wallet_01 .wallet_reminder_title {
  /* color: red; */
  font-size: 1.25rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
}

#wallet_01 .wallet_reminder_content {
  font-size: 1rem;
  white-space: pre-line;
}

#wallet_01 .form_required_input {
  /* color: red; */
  margin-left: .25rem;
  font-size: .65rem;
}

#wallet_01 .wallet_container .form-control:disabled,#wallet_01 .wallet_container .form-select:disabled {
  /* background: #333;
  color: #fff; */
  border: 0;
  height: 3.5rem;
}

#wallet_01 .wallet_container .form-control {
  padding: 0.65rem 0.75rem;
}

#wallet_01 .wallet_container .form-select {
  padding: 0.65rem 2.75rem 0.65rem 0.75rem;
}

#wallet_01 .form_input_wrap {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
}

#wallet_01 .form_input_wrap .input_wrap {
  width: 100%;
}

#wallet_01 .form_input_wrap .input_wrap_row {
  width: 100%;
  display: flex;
  flex-direction: column;
}

#wallet_01 .form_input_wrap .info_input_wrap {
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin: 0.5rem;
  color: #9b9b9b;
}

#wallet_01 .form_input_wrap .info_input_wrap span {
  margin-left: 0.5rem;
  font-size: .7rem;
}

#wallet_01 .wallet_container .form-control[type=file] {
  border: 1px solid transparent;
  background: transparent;
}


#wallet_01 .input_accountNumber::-webkit-outer-spin-button,
#wallet_01 .input_accountNumber::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#wallet_01 .input_accountNumber {
  appearance: textfield;
}


#wallet_01 .input_error_msg {
  color: red;
  font-size: .8rem;
  margin: 0;
}

#wallet_01 .uploadImageContainer {
  height: 9rem;
  width: 9rem;
  background-color: transparent;
  border: 2px dashed #ced4da;
  cursor: pointer;
  border-radius: .375rem;
}

#wallet_01 .beforeUploadImage {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#wallet_01 .beforeUploadImage span {
  margin-left: .25rem;
}

#wallet_01 .afterUploadImageContainer {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0.25rem;
  justify-content: center;
}

/* #wallet_01 .afterUploadImage{
  height: 100%;
} */

#wallet_01 .afterUploadImage img {
  width: 8rem;
  height: 8rem;
  object-fit: contain;
}

/* -----------  Wallet Container (Deposit) --------------- */

#wallet_01 .wallet_btnAmount {
  width: 100%;
  margin-top: .5rem;
  display: flex;
}

#wallet_01 .wallet_btnSubmit:disabled,
#bankingModal input[type="button"]:disabled,
#dobEditModal input[type="submit"]:disabled {
  width: 100%;
  opacity: 0.5;
  cursor: not-allowed;
}

#wallet_01 .wallet_btnAmount button {
  /* background: #fff;
  color: #000; */
  font-size: .85rem;
  /* border-radius: 0.375rem !important; */
  /* margin-right: .5rem; */
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
  padding: .3rem 0;
  max-width: 8rem;
}



/* #wallet_01 .wallet_btnAmount.btn-group>.btn {
  border-radius: 0.375rem !important;
} */

#wallet_01 .wallet_btnAmount button:not(:last-child) {
  margin-right: .5rem;
}

#wallet_01 .depositBank_col {
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */

  /* padding: .8rem 1rem 0; */
  /* margin-bottom: 1rem; */
  /* border-radius: 0.35rem;
  border: 1px solid #ced4da; */
}

#wallet_01 .depositBank_col label {
  font-size: 1rem;
  margin: 0.25rem;
}

#wallet_01 .depositBankImg {
  display: flex;
  flex-wrap: wrap;
  /* background: #fff; */
  /* padding: 1rem 1rem 0; */
  /* margin-bottom: 1rem; */
  /* border-radius: 0.35rem; */
  /* border: 1px solid #ced4da; */
  background: #fff;
  color: #000;
  border: 1px solid #ced4da;
  border-radius: 0.35rem;
  padding: 0.8rem 1rem 0;
}

#wallet_01 .depositBank_col+.depositBank_col {
  margin-top: .5rem;
}

#wallet_01 .depositBankImg.empty {
  padding: 0;
}

#wallet_01 .depositBankImg_col {
  /* margin-right: .5rem; */
  margin-bottom: 1rem;
  cursor: pointer;
  filter: grayscale(1) opacity(0.5);
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
  flex-grow: 1;
  font-size: .85rem;
  margin-bottom: .8rem;
  width: 100%;
  flex-direction: row;

}

#wallet_01 img.maintenance_img {
  width: auto;
  border: 1px solid var(--color-primary);
  border-radius: .375rem;
  /* margin-bottom: .5rem; */
}

#wallet_01 .depositBankImg_col.active {
  filter: none;
}

#wallet_01 .depositBankImg img {
  /* border: 1px solid var(--color-button);
  border-radius: .375rem; */
  width: 3.4rem;
  margin-right: .5rem;
}

#wallet_01 .wallet_cms {
  max-width: 25rem;
  margin: 5rem auto;
}

#wallet_01 .wallet_depositMethod_tab_m {
  padding: 0 1rem;
}

#wallet_01 .displayQR_row {
  display: flex;
  align-items: center;
  margin: 1rem 0 0;
  flex-direction: column;
}

#wallet_01 .displayQR_row img {
  width: 10rem;
  margin: 0 .5rem;
  cursor: pointer;
}

#wallet_01 .displayQR_row label {
  /* color: red; */
  /* padding-left: 1rem; */
  font-size: .65rem;
}

#wallet_01 .deposit_channel_container {
  display: flex;
  width: 100%;
  cursor: pointer;
}

#wallet_01 .deposit_channel_container .deposit_channel_row {
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#wallet_01 .deposit_channel_container .deposit_channel_row img {
  width: 90%;
}

#wallet_01 .deposit_banktransfer_info_container,
#wallet_01 .deposit_qrPay_info_container {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  margin: .5rem 0 0;

}

#wallet_01 .deposit_banktransfer_info,
#wallet_01 .deposit_qrPay_info {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

#wallet_01 .deposit_banktransfer_info .deposit_banktransfer_info_row,
#wallet_01 .deposit_qrPay_info .deposit_qrPay_info_row {
  position: relative;
}

#wallet_01 .deposit_banktransfer_info textarea,
#wallet_01 .deposit_qrPay_info textarea {
  resize: none;
}



#wallet_01 .deposit_banktransfer_info svg,
#wallet_01 .deposit_qrPay_info svg {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 1.25rem;
  cursor: pointer;
}


/* ADD NEW MOBILE DEPOSIT */
#wallet_01 .wallet_container_m .wallet_depositMethod {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-size: 1rem;
  margin: 1rem 0 0.5rem;
}

#wallet_01 .wallet_container_m .wallet_depositMethod .depositMethod_navItem .wallet_depositMethod_menu:focus-visible {
  outline: 0;
}

#wallet_01 .wallet_container_m .wallet_depositMethod .depositMethod_navItem {
  width: 25%;
}

#wallet_01 .wallet_container_m .wallet_depositMethod .depositMethod_navItem .wallet_depositMethod_menu {
  padding: 0.5rem;
  font-size: 1rem;
  background: inherit;
  color: inherit;
  text-align: center;
}

/* -----------  Wallet Container (Transfer) --------------- */
#wallet_01 .transfer_form {
  padding: 1rem 0.5rem;
}

#wallet_01 .transferFrom_col,
#wallet_01 .transferTo_col {
  width: 100%;
}

#wallet_01 .transferIcon_col {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  padding-bottom: 0;
  margin-bottom: -1.5rem;
  width: 100%;
  font-size: 2.5rem;
  margin-top: 0.5rem;
  z-index: 2;
}

/* -----------  Wallet Container (Transfer) - Game Wallet --------------- */

/* -----------  Desktop --------------- */

#wallet_01 .wallet_gameWallet {
  position: relative;
}

#wallet_01 .wallet_gameWallet_container {

  width: 100%;
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

#wallet_01 .wallet_gameWallet_wrap {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}

#wallet_01 .wallet_gameWallet_row {
  width: 100%;
}

#wallet_01 .wallet_gameWallet_action_topBar {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
  position: absolute;
  right: 0;

}

#wallet_01 .wallet_gameWallet_title {
  text-transform: uppercase;
  padding: 1rem 0 .5rem;
  border-bottom: 1px solid #000000;
}

#wallet_01 .wallet_gameWallet_subTitle {
  font-size: 16px;
  color: #999;
  font-weight: 500;
  text-transform: uppercase;
  padding: .5rem 0;
}

#wallet_01 .wallet_gameWallet_row:first-child .wallet_gameWallet_subTitle {
  padding: 1rem 0;
}

#wallet_01 .wallet_gameWallet_box {
  display: flex;
  align-items: center;
  width: 24.25%;
  padding: 8px;
  margin-bottom: 1%;
  /* border: 1px solid #000000; */
  border-radius: 7px;
  font-size: 14px;
  font-weight: 500;
}

#wallet_01 .wallet_gameWallet_box:not(:nth-child(4n)) {
  margin-right: 1%;
}

#wallet_01 .wallet_gameWallet_boxContent {
  width: 65%;
}

#wallet_01 .wallet_gameWallet_gameName {
  padding: 0.25rem 0;
  font-size: 11px;
  white-space: nowrap;
}

#wallet_01 .wallet_gameWallet_gameImage {
  padding: .25rem 0;
}

#wallet_01 .wallet_gameWallet_gameImage img {
  height: 1.75rem;
  width: auto;
}

#wallet_01 .wallet_gameWallet_boxContent2 {
  width: 70%;
  height: 100%;
  text-align: unset;
}

#wallet_01 .wallet_gameWallet_button {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}

/* #wallet_01 .wallet_gameWallet_button button:nth-child(1) { */
/* margin-right: .25rem; */
/* } */


#wallet_01 .wallet_gameWallet_button button {
  padding: 5px 7px;
  border-radius: 0.35rem;
  cursor: pointer;
  outline: none;
  border: none;
  float: right;
  text-transform: uppercase;
  white-space: nowrap;
  width: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  line-height: initial;
  font-size: .85rem;
}

#wallet_01 .wallet_gameWallet_gameBalance {
  font-size: 1.15rem;
  font-weight: 700;
  width: 100%;
  text-align: end;
  background: 0 0;
  border: none;
  outline: none;
}

#wallet_01 .wallet_gameWallet_btnRefresh {
  border: none;
  padding: 0.35rem 1.5rem;
  min-width: 9.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

/* -----------  Mobile --------------- */

#wallet_01 .wallet_gameWallet_accordion_item {
  border: 0;
}

/* #wallet_01 .wallet_gameWallet_accordion_title button {
  background: var(--color-button); 
 color: #fff;
} */

#wallet_01 .wallet_gameWallet_accordion_title button:focus {
  box-shadow: none;
}

#wallet_01 .wallet_gameWallet_accordion_details {
  /* background: #333;
  color: #fff; */
  padding: 0
}

#wallet_01 .wallet_gameWallet_accordion_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: .5rem 2rem;
}

#wallet_01 .wallet_gameWallet_accordion_gameDetails {
  display: flex;
  align-items: center;
}

#wallet_01 .wallet_gameWallet_accordion_gameAllIn {
  margin-left: 1rem;
  padding: 0.2rem 1.25rem;
  border: 0;
  border-radius: 0.345rem;
}

#wallet_01 .wallet_gameWallet_accordion_item .accordion-button::after {
  /* content: '\2BC6'; */
  background-image: none;
  /* color:#000000; */
  border-bottom: solid 0px transparent;
  border-top: solid 10px;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
  transform: scale(.5);
}

#wallet_01 .wallet_gameWallet_accordion_item .accordion-button:not(.collapsed)::after {
  /* color:#ffffff; */
  transform: scale(.5) rotate(180deg);

}

.wallet_gameWallet_refresh_row {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  background: rgba(230, 230, 230, 1);
  padding: 0.65rem 3rem;
  font-size: 1.25rem;
}

/* -----------  Wallet Container (Withdrawal) --------------- */

#wallet_01 .withdraw_form {
  padding: 0 0.5rem 1rem;
}

/* -----------  Wallet Container (History) --------------- */

/* #wallet_01 .history_form .btn-group>.btn {
  border-radius: 0.375rem;
}

#wallet_01 .history_form .btn-group>.btn-group:not(:last-child)>.btn,
#wallet_01 .history_form .btn-group>.btn.dropdown-toggle-split:first-child,
#wallet_01 .history_form .btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
  margin-right: 1rem;
} */

.transactionTypeTab {
  /* background: transparent; */
  width: 100%;
  margin: .5rem 0;
  text-align: center;
  padding: .25rem;
  display: flex;
  flex-wrap: unset;
}


.transactionTypeTab .transactionType_selection {
  /* padding: 0.5rem 1.75rem;
  text-transform: uppercase;*/
  white-space: nowrap;
  /* background: #fff;
  color: #000; */
  font-size: .85rem;
  /* border: none;
  padding: 0.8rem 1rem;
  margin-right: 1rem; */
  cursor: pointer;
}

/* .transactionTypeTab .transactionType_selection.active { */
/* padding: 0.5rem 1.75rem;
  text-transform: uppercase;*/
/* border-radius: .35rem;
  background: red;
  color: #fff; */
/* } */

#wallet_01 .history_form .form-control[type="date"] {
  height: 100%;
  /* border: 1px solid #9c9c9c;
  border-radius: 0; */
  padding: 0 .5rem;
  /* color: #9c9c9c; */
  font-size: .8rem;
  display: flex;
  align-items: center;
}

#wallet_01 .history_date_label {
  white-space: nowrap;
  width: 6.5rem;
  /* font-size: .95rem; */
}

#wallet_01 .table.wallet_history_table {
  border-bottom: 1px solid #ccc;
  margin: 4rem 0 0.75rem;
  text-align: center;
  vertical-align: middle;
  word-break: break-all;
}

#wallet_01 .table.wallet_history_table thead {
  font-size: 1rem;
}

#wallet_01 .table.wallet_history_table tbody {
  font-size: .95rem;
  white-space: pre;
}

#wallet_01 .table.wallet_history_table tbody tr td {
  -webkit-text-align: center;
  text-align: -webkit-center;
  text-align: center;
  /* line-height: 7px; */
}

#wallet_01 .table.wallet_history_table tbody tr td:last-of-type div:nth-child(1) {
  color: var(--color-theme);
  text-decoration: underline;
  text-transform: capitalize;
  cursor: pointer;
}

#wallet_01 .table.wallet_history_table tbody tr td:last-of-type div:nth-child(2) {
  color: red;
  font-weight: 600;
  display: block;
  overflow-wrap: break-word;
  white-space: normal;
}

#wallet_01 .history_noRecord_wrap {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5rem
}

#wallet_01 .history_noRecord_wrap label {
  margin-top: .5rem;
}

/* ADD NEW MOBILE DEPOSIT */
#wallet_01 .wallet_container_m .transactionTypeTab {
  width: 100%;
  margin: 0.5rem 0;
  text-align: center;
  /* padding: 0.25rem; */
  display: flex;
  flex-wrap: unset;
  overflow: auto;
}

#wallet_01 .wallet_container_m .transactionTypeTab .transactionType_selection {
  font-size: 1rem;
}

/* #wallet_01 .wallet_container_m .wallet_depositMethod .depositMethod_navItem {
  width: 25%;
}

#wallet_01 .wallet_container_m .wallet_depositMethod .depositMethod_navItem .wallet_depositMethod_menu{
  padding: 0.5rem;
  font-size: 1rem;
  background: inherit;
  color: inherit;
} */

/* -----------  Mobile --------------- */

#wallet_01 .wallet_history_tab {
  display: flex;
  overflow-x: scroll;
  flex-wrap: initial;
  overflow-y: hidden;
  padding: 0 1rem;
}

#wallet_01 .wallet_history_tabSelection {
  white-space: nowrap;
  min-width: 8rem;
}

#wallet_01 .wallet_body_container_m .history_form .wallet_btnSubmit.btnSearch {
  clip-path: circle(40% at 50% 50%);
  margin-top: 0;
  border-radius: 50%;
  width: 3rem;
}

#wallet_01 .history_form .wallet_btnSubmit.btnSearch svg {
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
}

#wallet_01 .history_paginationButton button {
  /* padding: .25rem .85rem; */
  margin: 0 0.5rem 0 0;
  /* border-radius: 1rem; */
  font-size: .85rem;
}

#wallet_01 .history_current_showing {
  font-size: .87rem;
}




#wallet_01 .history_paginationButton {
  margin-top: 0.5rem;
}

#historyDetailsDialog .historyDetails_content_row {
  padding: 0 0 1rem;
}

#historyDetailsDialog label {
  font-weight: 500;
  color: var(--color-theme);
}

#historyDetailsDialog.modal-dialog {
  width: 30rem;
  margin: auto;
}

/* -----------  Wallet Container (User Info) --------------- */


#wallet_01 .myAccount_userInfo_profile .list-group-item {
  border: 0;
  background: unset;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  padding-left: 0;
}

#wallet_01 .myAccount_userInfo_profile .subTitle {
  padding-bottom: 0.5rem;
  font-weight: 600;
}




#wallet_01 .userInfo_bankDetails_card {
  display: flex;
  align-items: center;
  margin: .5rem 0;
}

#wallet_01 .userInfo_bankDetails_bankLogo {
  width: 5rem;

}

#wallet_01 .userInfo_bankDetails_bankProfile {
  margin: 0 1rem;
  font-size: 1.25rem;
}

#wallet_01 .userInfo_bankDetails_icon {
  color: green;
  font-size: 1.5rem;
}

/* -----------  Mobile --------------- */

#wallet_01 .myAccount_userInfo_profile {
  /* height: 100%; */
  /* background: #f2f2f2; */
  padding: 0 1.5rem;
  border-radius: 0;
}

#wallet_01 .myAccount_userInfo_profile_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  padding: 1.5rem 0;
}

#wallet_01 .myAccount_userInfo_profile_row img {
  width: 3.5rem;
  margin-right: 1rem;
}

#wallet_01 .myAccount_userInfo_profile_row svg {
  color: #000;
}

#wallet_01 .myAccount_userInfo_profile {
  /* height: 100%; */
  /* background: #f2f2f2; */
  padding: 0 1.5rem 1.5rem;
  border: 0;
  color: inherit;
}

#wallet_01 .myAccount_userInfo_profile label {
  /* color: var(--color-button); */
  width: 50%;
}

#wallet_01 .myAccount_userInfo_profile label+p {
  /* color: var(--color-button); */
  width: 50%;
}

/* REAL NAME VERIFICATION START*/
.realNameVerification_header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: space-between;
}

.myAccount_userInfo_profile .realNameVerification_title {
  width: 23%;
}

#wallet_01 .realNameVerification_container .list-group-item {
  margin-bottom: 1rem;
  text-align: center;
  border: 0;
}

#wallet_01 .myAccount_userInfo_profile .verifyNow_button {
  padding: 0.75rem 1rem;
  /* border-radius: 25px; */
}

#wallet_01 .myAccount_userInfo_profile .realNameVerification_container .wallet_btnSubmit {
  width: 50%;
  z-index: 2;
}

.realNameVerification_container .wallet_btnSubmit {
  width: 50%;
  z-index: 2;
}

#wallet_01 .realNameVerification_container label {
  /* color: var(--color-button); */
  width: 23%;
  display: none;
}


#wallet_01 .realNameVerification_container label+img {
  max-height: 10rem;
}

#wallet_01 .realNameVerification_container .uploadImageContainer {
  width: 100%;
  height: 15rem;
}

#wallet_01 .realNameVerification_container .beforeUploadImage div {
  display: flex;
  align-items: center;
}

#wallet_01 .realNameVerification_container .beforeUploadImage {
  flex-direction: column;
}

#wallet_01 .realNameVerification_container .afterUploadImage img {
  width: 100%;
  ;
  height: 15rem;
  object-fit: contain;
}

#wallet_01 .realNameVerification_container .realNameVerification_viewSample_button {
  padding: 0.75rem 1rem;
  /* border-radius: 25px; */
  font-size: .8rem;
}

#wallet_01 .realNameVerification_container .reminder_container {
  padding: 1rem 0;
}

#wallet_01 .realNameVerification_container .reminder_title {
  color: red
}

#wallet_01 .realNameVerification_container .reminder_content {
  white-space: pre-line;
}

#verificationSampleModal.modal-dialog {
  /* width: 30rem; */
  margin: 10px;
}

#verificationSampleModal .modal-body {
  /* max-height: 30rem; */
  overflow: auto;
}

/* ---  REAL NAME VERIFICATION END*/

/* -----------  Wallet Container (Notification) --------------- */


#wallet_01 .myAccount_notification_header {
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#wallet_01 .myAccount_notification_selectAll {
  display: flex;
  align-items: center;
}

#wallet_01 .myAccount_notification_selectAll label {
  margin-left: 1.25rem;
}



#wallet_01 .myAccount_notification_title_row {
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  /* background: #f2f2f2; */
  /* color: #000; */
  /* border-bottom: 1px solid #ccc; */
}

#wallet_01 .myAccount_notification_titleContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 1.25rem;
}

#wallet_01 .myAccount_notification_titleContent.unread {
  font-weight: bold;
}

#wallet_01 .myAccount_notification_action {
  display: flex;
  align-items: center;
  cursor: pointer;
}

#wallet_01 .myAccount_notification_action div {
  display: flex;
}

#wallet_01 .myAccount_notification_content {
  /* background: #000;
  color: #fff; */
  padding: .5rem 1.5rem;
  font-size: 1.25rem;
  white-space: pre-line;
}

/* -----------  Wallet Container (Change Password) --------------- */

#wallet_01 .changePassword_form,
#wallet_01 .changeGamePassword_form {
  padding: 1rem
}

#wallet_01 .myAccount_changePasswordTab .myAccount_changePasswordTab_selection {
  background: #999;
  color: #ccc;
  margin-right: .5rem;
}

#wallet_01 .myAccount_changePasswordTab .myAccount_changePasswordTab_selection.active {
  background: var(--color-button);
  color: #fff;
}

/* -----------  Wallet Container (Banking Details) --------------- */



#wallet_01 .myAccount_bankingDetails_tableHeader tr th {
  padding: 1rem 0;
}

#wallet_01 .myAccount_bankingDetails_table tr td img {
  width: 3.5rem;
}

#wallet_01 .myAccount_bankingDetails_table tr td:first-child {
  text-align: center;
}

#wallet_01 .myAccount_bankingDetails_table tr td {
  display: table-cell;
  vertical-align: middle;
  max-width: 15rem;
}

#wallet_01 .myAccount.bankingDetails .bankingInfo_reminder_container {
  min-height: 31vh;
  margin-top: 0rem;
}

#wallet_01 .myAccount_bankingDetails_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  margin-bottom: 1rem;
}

#wallet_01 .bankingDetails_row_img {
  width: 5rem;
  margin-right: 1rem;
  overflow: hidden;
}

#wallet_01 .bankingDetails_row_details {
  display: flex;
  flex-direction: column;
}

#wallet_01 svg.verify_banking {
  color: green
}

#bankingModal .form_required_input {
  /* color: red; */
  margin-left: .25rem;
  font-size: .65rem;
}

#bankingModal .form-control:disabled {
  /* background: #333;
  color: #fff; */
  border: 0;
}

#bankingModal .wallet_container .form-control,
#bankingModal .wallet_container .form-select {
  padding: 0.65rem 0.75rem;
}

#bankingModal .form_input_wrap {
  margin: 0.5rem 0;
}

#bankingModal input[type="submit"] {
  width: 100%;
  padding: .75rem;
  margin-top: .75rem;
}

/* -----------  Mobile To Desktop  --------------- */
@media (min-width: 992px) {

  #wallet_01 .wallet_body {
    padding: 4rem 0;
  }

  #wallet_01 .form-label {
    font-size: 1rem;
  }

  #wallet_01 .wallet_container .form-control:disabled:not(textarea.form-control:disabled) {
    height: auto;
  }

  /* -----------  Wallet Bar --------------- */
  #wallet_01 .walletBar_selection {
    display: flex;
    align-items: center;
    margin-bottom: .5rem;
    font-size: 1rem;
    flex-direction: row;
    padding: 0.5rem;
    word-break: break-word;
  }

  #wallet_01 .walletBar_balanceAmount {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }


  /* -----------  Wallet Container --------------- */


  #wallet_01 .wallet_container {
    width: 75%;
    /* background-color: #ffffff; */
    color: #000000;
    padding: 0;
    /* border: 1px solid #ffffff; */
    /* border-radius: 0.5rem; */
  }

  #wallet_01 .wallet_container_wrap {
    padding: 1rem 1.5rem;
    min-height: 100vh;
  }

  #wallet_01 .myFriend_body .wallet_container_wrap {
    padding: 1rem 0;
    /* min-height: 100vh; */
  }

  #wallet_01 .wallet_reminder_container {
    /* background: #000;
    color: #fff; */
    padding: 1rem;
    min-height: 65vh;

  }

  #wallet_01 .wallet_reminder_title {
    /* color: red; */
    font-size: 1.35rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  #wallet_01 .wallet_reminder_content {
    font-size: .85rem;
    white-space: pre-line;
  }

  /* -----------  Wallet Container (Deposit) --------------- */

  #wallet_01 .wallet_depositMethod {
    margin-bottom: 1rem;
    width: 100%;
  }

  #wallet_01 .wallet_depositMethod .nav-item {
    width: 25%;
  }

  #wallet_01 .wallet_depositMethod .wallet_depositMethod_menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: transparent;
    /* color: #7e7e7e; */
    padding: 0;
  }

  #wallet_01 .wallet_depositMethod .wallet_depositMethod_menu.active {
    background: transparent;
    /* color: #000; */
  }

  #wallet_01 .wallet_depositMethod img {
    width: 90%;
    margin-right: auto;
  }

  #wallet_01 .wallet_depositMethod img+div {
    width: 90%;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  #wallet_01 .depositBankImg_col {
    width: 50%;
  }

  #wallet_01 .displayQR_row {
    display: flex;
    align-items: center;
    margin: 0;
    flex-direction: column;
  }

  #wallet_01 .displayQR_row img {
    width: 10rem;
    margin-right: .5rem;
    cursor: pointer;
  }

  #wallet_01 .displayQR_row label {
    /* color: red; */
    /* padding-left: 1rem; */
    font-size: .65rem;
  }

  #wallet_01 .deposit_channel_container {
    display: flex;
    width: 100%;
    cursor: pointer;
  }

  #wallet_01 .deposit_channel_container .deposit_channel_row {
    width: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: .75rem;
  }

  #wallet_01 .deposit_channel_container .deposit_channel_row img {
    width: 90%;
    margin-right: auto;
  }

  #wallet_01 .deposit_channel_container .deposit_channel_row img+div {
    width: 90%;
    margin-right: auto;
    display: flex;
    justify-content: center;
  }

  #wallet_01 .deposit_banktransfer_info_container,
  #wallet_01 .deposit_qrPay_info_container {
    display: flex;
    flex-direction: row-reverse;
    align-items: stretch;
    justify-content: center;
  }

  #wallet_01 .deposit_banktransfer_info,
  #wallet_01 .deposit_qrPay_info {
    display: flex;
    flex-direction: column;
    flex: 1
  }

  #wallet_01 .deposit_banktransfer_info .deposit_banktransfer_info_row:nth-child(2),
  #wallet_01 .deposit_qrPay_info .deposit_qrPay_info_row:nth-child(2) {
    flex-grow: 1;
    resize: none;
    height: 2.8rem;
  }

  #wallet_01 .deposit_banktransfer_info textarea.form-control:disabled,
  #wallet_01 .deposit_qrPay_info textarea.form-control:disabled {
    height: 100%;
  }

  /* -----------  Wallet Container (Transfer) --------------- */

  #wallet_01 .transferFrom_col,
  #wallet_01 .transferTo_col {
    width: 45%;
  }

  #wallet_01 .transferIcon_col {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: .25rem;
    margin-bottom: .5rem;
    width: 10%;
    font-size: 1.5rem;
    margin-top: 0;
  }

  #wallet_01 .form_input_wrap .input_wrap_row {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  #wallet_01 .transfer_form .form_input_wrap {
    width: 100%;
  }

  /* #wallet_01 .transfer_form .form_input_wrap+ input{
    width: 40%;
  } */
  /* -----------  Wallet Container (Withdraw) --------------- */

  #wallet_01 .withdraw_form {
    padding: 1rem 0.5rem;
  }

  /* -----------  Wallet Container (History) --------------- */

  #wallet_01 .history_form .wallet_btnSubmit.btnSearch {
    margin-top: 0;
    clip-path: none;
    height: 100%;
    padding: 0;
    width: 100%;
    /* border-radius: .35rem; */
  }



  /* #wallet_01 .history_paginationButton button {
    padding: .25rem .5rem
  } */


  /* -----------  Wallet Container (Notification) --------------- */

  #wallet_01 .myAccount_notification_titleContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 1.25rem;
    width: 100%;
  }

  #wallet_01 .myAccount_notification_content {
    /* background: #000;
    color: #fff; */
    padding: .5rem 1.5rem;
    font-size: 1rem;
  }

  /* -----------  Wallet Container (User Info) --------------- */

  #wallet_01 .myAccount_userInfo_profile {
    /* height: 100%; */
    /* background: #f2f2f2; */
    padding: 0 0 1rem;
    border: 0;
  }

  #wallet_01 .myAccount_userInfo_profile label {
    /* color: var(--color-button); */
    width: 50%;
  }

  #wallet_01 .myAccount_userInfo_profile label+p {
    /* color: var(--color-button); */
    width: 50%;
  }

  /* REAL NAME VERIFICATION START*/
  .myAccount_userInfo_profile .realNameVerification_header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: start
  }

  .myAccount_userInfo_profile .realNameVerification_title {
    width: 23%;
  }

  #wallet_01 .myAccount_userInfo_profile .realNameVerification_container .list-group-item {
    margin-bottom: 1rem;
  }

  #wallet_01 .myAccount_userInfo_profile .realNameVerification_container .wallet_btnSubmit {
    width: 50%;
  }

  #wallet_01 .myAccount_userInfo_profile .realNameVerification_container label {
    /* color: var(--color-button); */
    width: 23%;
    display: block;
  }


  #wallet_01 .myAccount_userInfo_profile .realNameVerification_container label+img {
    height: 10rem;
  }

  #wallet_01 .realNameVerification_container .uploadImageContainer {
    width: 20rem;
    height: 10rem;
  }

  #wallet_01 .realNameVerification_container .beforeUploadImage div {
    display: flex;
    align-items: center;
  }

  #wallet_01 .realNameVerification_container .beforeUploadImage {
    flex-direction: column;
  }

  #wallet_01 .realNameVerification_container .afterUploadImage img {
    width: 18rem;
    height: 9rem;
    object-fit: contain;
  }

  #wallet_01 .realNameVerification_container .realNameVerification_viewSample_button {
    padding: 0.75rem 1rem;
    /* border-radius: 25px; */
    font-size: .8rem;
  }

  #wallet_01 .realNameVerification_container .reminder_container {
    padding: 1rem 0;
  }

  #wallet_01 .realNameVerification_container .reminder_title {
    color: red
  }

  #wallet_01 .realNameVerification_container .reminder_content {
    white-space: pre-line;
  }

  #verificationSampleModal.modal-dialog {
    width: 30rem;
    margin: auto;
  }

  #verificationSampleModal .modal-body {
    /* max-height: 30rem; */
    overflow: auto;
  }

  /* ---  REAL NAME VERIFICATION END*/

  #wallet_01 .userInfo_bankDetails_card {
    display: flex;
    align-items: center;
    margin: .5rem 0;
  }

  #wallet_01 .userInfo_bankDetails_bankLogo {
    width: 3rem;


  }

  #wallet_01 .userInfo_bankDetails_bankProfile {
    margin: 0 1rem;
    font-size: inherit;
  }

  #wallet_01 .userInfo_bankDetails_icon {
    color: green;
    font-size: 1.25rem;
  }

  /* #dobEditModal.modal-dialog {
    width: auto;
  } */

}

/* -----------  Wallet Container (My Friend) --------------- */
/* MY FRIEND HEADER SECTION */
.myFriend_header_section {
  width: 100%;
  display: flex;
  align-items: center;
}

.myFriendSearchBar {
  width: 70%;
  display: flex;
  align-items: center;
  padding: 0 1.5rem;
}

.myFriendSearchBar .input_search {
  width: 80%;
  display: flex;
  align-items: center;
  flex: 1;
}

#wallet_01 .myFriendSearchBar button {
  width: 20%;
  margin: 0 0.5rem;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  padding-top: rem;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.myFriendSelectionWrap {
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.myFriendSelectionWrap .myFriendSelection {
  display: flex;
  align-items: center;
  margin-right: .75rem;
  cursor: pointer;
  position: relative;
}

.myFriendSelectionWrap .myFriendSelection img {
  height: 3rem;
  margin-right: .25rem;

}

/* MOBILE */
.wallet_container_m .myFriend_header_section {
  flex-direction: column;
}

.wallet_container_m .myFriendSearchBar {
  width: 100%;
}

.wallet_container_m .myFriendSelectionWrap {
  width: 100%;
  justify-content: start;
  padding: 0 1.5rem;
  margin-top: 1rem;
}

/* MY FRIEND SEARCH FRIEND SECTION */
.searchFriend_wrap {
  margin: 2rem 0;
  padding: 0 1.5rem;

}

.searchFriend_container {
  display: flex;
  align-items: center;
  width: 100%;
  /* border-radius: .5rem;
  border: 1px solid #e7e7e7;
  padding: 1rem;
  margin-bottom: 1rem; */
}

.searchFriend_container .searchFriend_avatar img {
  height: 3.5rem;
  margin-right: 1rem;
}

.searchFriend_container .searchFriend_info {
  flex: 1;
  font-size: 1.175rem;
}

.searchFriend_container .searchFriend_action {
  flex: 1;
  display: flex;
}

#wallet_01 .searchFriend_container .searchFriend_action button {
  width: auto;
  min-width: 10rem;
  margin: 0;
  margin-right: 1rem
}

/* MY FRIEND REQUEST LIST*/
.friendRequest_wrap {
  padding: 0 1.5rem;
}

/* .friendRequest_tab {
  background: rgba(231, 231, 231, 1);
  border-radius: 20px 20px 20px 20px;
} */

.friendRequest_tab .nav-item {
  width: 50%;
}

/* .friendRequest_tab .friendRequestTab_selection.active {
  background: red;
  color: #fff;
}

.friendRequest_tab .friendRequestTab_selection {
  border: 0;
  border-radius: 20px 20px 20px 20px;
} */

/* FRIEND LIST */
.friendList_wrap {
  margin: 2rem 0;
}

/* .friendList_group_title {
  padding: .75rem 2rem;
  background: #e7e7e7;
  color: #000;
} */

.friendList_group_item_container {
  padding: 0 2rem
}

.friendList_group_item {
  display: flex;
  align-items: center;
  width: 100%;
  /* border-radius: .5rem;
  border: 1px solid #e7e7e7;
  padding: .75rem 1rem;
  border-right: 0;
  border-left: 0; */
  cursor: pointer;
}

.friendList_group_item .friendList_avatar img {
  height: 4rem;
  margin-right: 1rem;
}

.friendList_group_item .friendList_info {
  flex: 1;
  display: flex;
  align-items: center;
}

.friendList_group_item .friendList_info div {
  flex: 1;
  font-size: 1.15rem;
}

.friendList_group_item .friendList_action {
  /* flex: 1; */
}

#wallet_01 .friendList_group_item .friendList_action button {
  width: auto;
  min-width: 10rem;
  margin: 0;
  margin-right: 1rem
}

/* Friend Detail */
.friendDetail_wrap {
  padding: 0 1.5rem
}

.friendDetail_group_item {
  display: flex;
  align-items: center;
  width: 100%;
  /* border-radius: .5rem;
  border: 1px solid #e7e7e7;
  padding: .75rem 1rem;
  margin-bottom: 1rem; */

}

.friendDetail_group_item .friendDetail_avatar img {
  height: 5rem;
}

.friendDetail_group_item .friendDetail_info {
  margin: 0 1rem;
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;
}

.friendDetail_group_item .friendDetail_info div {
  /* margin-bottom: .25rem; */
}

/* TRANSFER FRIEND */
#createPasswordDialog .form_field,
#friendTransferDialog .form_field,
#friendTransferPasswordDialog .form_field {
  margin-bottom: 1rem;
}

#createPasswordDialog .form-label,
#friendTransferDialog .form-label,
#friendTransferPasswordDialog .form-label {
  display: flex;
  align-items: center;
}

#createPasswordDialog .wallet_btnSubmit,
#friendTransferDialog .wallet_btnSubmit,
#friendTransferPasswordDialog .wallet_btnSubmit {
  width: 100%;
  padding: .75rem
}


.searchFriend_container .searchFriend_action {
  justify-content: end;
}


.friendList_info_status.status_ACTIVE span {
  padding: 5px 20px;
  background: #CDFFBB;
  color: #377E22;
  font-size: .9rem;
  font-weight: 600;
  border-radius: 5px;
}

.friendDetail_info_status.status_ACTIVE {
  padding: 5px 20px;
  background: #CDFFBB;
  color: #377E22;
  font-size: .9rem;
  font-weight: 600;
  border-radius: 5px;
}

/* Mobile to Desktop My Friend */
@media (min-width: 992px) {

  .friendList_group_item .friendList_avatar img {
    height: 3rem;
    margin-right: 1rem;
  }

  .friendList_group_item .friendList_info div {
    font-size: 1.15rem;
  }

}

/* -----------  -------------------  --------------- */

.wallet_depositMethod_menu.nav-link:focus,
.wallet_depositMethod_menu.nav-link:hover {
  color: inherit
}


.table>:not(caption)>*>* {
  background-color: initial;
  color: inherit;

}


.form_required_input+svg {
  color: #2dc100;
}

.wallet_container_m .realNameVerification_title {
  padding: 0 0 0 1rem;
}


/* select{
  text-align: start;
}

option, optgroup {
  text-align: center;
} */

/* optgroup{
  color: pink;
} */

option,
optgroup {
  padding: 10px
}

.wallet_history_table_wrap {
  overflow-x: scroll;
  margin-bottom: .75rem;

}



.wallet_point {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.wallet_point svg {
  color: red;
  margin-left: .25rem;
  cursor: pointer;
}


.walletBar_selection.disabled{
  opacity: .5;
  pointer-events: none;
  cursor: not-allowed;
}

.myFriendSelection .noti_dot {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: auto;
  height: auto;
  border-radius: 0.75rem;
  padding: 0.2rem 0.45rem;
  top: -3px;
  left: -3px;
  background: red;
  line-height: 1rem;
  position: absolute;
  font-size: .75rem;
  outline: 1px solid #fff;
}