#banner_01 {
    max-width: 100%;
    z-index: 1;
    margin: unset;
}

/* #banner_01 .swiper-pagination-bullet{
    background: #ccc;
    opacity: 1;
  } */

/* #banner_01 .swiper-pagination-bullet-active{
    background: var(--color-primary);

} */

#banner_01 .swiper-button-next,
#banner_01 .swiper-button-prev {
    color: #000;
}

#banner_01 img,#slot_banner img ,#casino_banner img,#lottery_banner img,#esport_banner img,#fishing_banner img,#sport_banner img,#vip_banner img{
    width: 100%;
}