#help_01 .help_body {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.help_accordion {
  min-height: 60vh;
}
#help_01 .help_accordion .accordion-item {
  margin-bottom: 1rem;
  background-color: unset;
}

#help_01 .help_accordion .accordion-item,
#help_01 .help_accordion .accordion-item:last-of-type .accordion-button.collapsed {
  /* border-radius: .8rem; */
}

#help_01 .help_accordion .accordion-item .accordion-button {
  /* background: #ffffff;
  color:#000000;
  border: 1px solid red; */
  /* border-radius: 0.8rem; */
}

#help_01 .help_accordion .accordion-item .accordion-button:not(.collapsed) {
  /* background: linear-gradient(to left,#2e3192, #00a4ff, #2e3192);
  color:#ffffff;
  border: 1px solid red; */
  box-shadow: none;
  z-index: 1;
}



#help_01 .help_accordion .accordion-item .accordion-button::after {
  /* content: '\2bc6'; */
  background-image: none;
  /* color:#000000; */
  border-bottom: solid 0px transparent;
  border-top: solid 10px;
  border-left: solid 10px transparent;
  border-right: solid 10px transparent;
  transform: scale(.5);
}

#help_01 .help_accordion .accordion-item .accordion-button:not(.collapsed)::after {
  /* color:#ffffff; */
  transform: scale(.5) rotate(180deg);
}

#help_01 .help_accordion .accordion-item .accordion-button::before {
  content: '+';
  background-image: none;
  /* color:#000000; */
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-right: .5rem;
  transition: var(--bs-accordion-btn-icon-transition);
  display: flex;
  align-items: center;
}

#help_01 .help_accordion .accordion-item .accordion-button:not(.collapsed)::before {
  content: '-';
  color: inherit;
}

#help_01 .help_accordion .accordion-item {
  border: none;
  white-space: normal;
}


#help_01 .help_accordion .accordion-item .accordion-body {
  padding: 2rem 1.25rem 1rem 1.25rem
}

#help_01 .help_accordion .accordion-item .accordion-collapse {
  position: relative;
  top: -10px;
  margin: 1px;
  /* padding: 1.5rem 1.25rem 1rem; */
}


@media (max-width: 992px) {

  .help_accordion {
    min-height: 70vh;
  }

  .help_accordion .accordion-item .accordion-button {
    font-size: 1rem;
    display: flex;
    align-items: center;
  }
}

.help_accordion .accordion-item .accordion-button {
  font-size: 1.25rem;
  display: flex;
  align-items: center;
}