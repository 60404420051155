#loginM_01 .login_container_wrap {
    width: 70%;
    margin: auto;
    height: 70vh;
}

#loginM_01 .login_form_line {
    display: none;
}

#loginM_01 .login_form .form_field {
    margin-bottom: 1rem;
    position: relative;
}

#loginM_01 .login_form .form-label {
    display: flex;
    align-items: center;
    padding-right: 0.5rem;
}

#loginM_01 .login_form .form-control,
#loginM_01 .login_form .form-select {
    padding: 0.75rem
}

#loginM_01 .login_form .form_required {
    color: red;
    margin-left: .25rem;
}

#loginM_01 .login_btnLogin {
    width: 100%;
    padding: 0.75rem;
    height: auto;
}

/* #loginM_01 .login_btnSend {
    position: absolute;
    right: 0.8rem;
    font-size: .8rem;
    padding: 0 0.5rem;
    top: 0.7rem;
} */

#loginM_01 .login_btnSendCode,
#loginM_01 .login_btnVerify {
    position: absolute;
    right: 0.8rem;
    font-size: .8rem;
    padding: 0.25rem 0.5rem;
    /* top: 25%; */
    transform: translate(0, 0%);
    min-width: 4rem;
    border-radius: .25rem;
}


#loginM_01 .login_form_reminder {
    padding: 1rem 0;
}

#loginM_01 .login_form_reminder_title {
    color: red;
    font-size: 1.25rem;
}

#loginM_01 .login_form_reminder_content {
    font-size: 1rem;
}

/* #loginM_01 .login_form_step {
    margin: 3rem 0 0 0;
} */

#loginM_01 .login_error_msg {
    color: red;
    font-size: .8rem;
    margin: 0;
}

#loginM_01 .form_field_input {
    width: 100%;
}

#loginM_01 .form_field_input.input_submit_wrap {
    text-align: end;
}

#loginM_01 input[type="submit"]:disabled,
#loginM_01 button:disabled {
    filter: opacity(0.5);
    cursor: no-drop;
}

.verifyDialog .form_field {
    display: flex;
    align-items: center;
}

.verifyDialog .form_field .form_field_input div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.verifyDialog .form_field .form_field_input div input {
    width: 50%;
}

.verifyDialog .modal-dialog {
    width: 30rem;
    margin: auto;
}

.verifyDialog .form-label {
    margin: 0 1rem 0 0;
    white-space: nowrap;
}

.verifyDialog input[type="button"],
.verifyDialog button {
    margin: 0 .5rem 0 0.5rem;

}

.verifyDialog input[type="button"],
.verifyDialog button,
.verifyDialog input[type="submit"] {
    padding: 0.25rem 0.5rem;
    height: auto;
    border-radius: 0.25rem;
    min-width: 5rem;
}

.verifyDialog input[type="button"]:disabled {
    filter: opacity(0.5);
    cursor: no-drop;
}

.verifyDialog .modal-footer {
    justify-content: flex-end;
}

.verifyDialog .error_msg{
    color: red;
    font-size: .8rem;
    margin: 0;
}

#loginM_01 .login_form_reminder {
    padding: 1rem 0;
}

#loginM_01 .login_form_reminder_title {
    color: red;
    font-size: 1.25rem;
}

#loginM_01 .login_form_reminder_content {
    font-size: 1rem;
}


/* Mobile to Desktop */
@media (min-width: 992px) {
    #loginM_01 .login_body {
        display: flex;
        align-items: center;
        justify-content: center;
        /* height: 75vh; */
    }

    #loginM_01 .login_form_line {
        display: block;
    }

    #loginM_01 #login_title_d {
        padding: .5rem;

    }

    #loginM_01 .login_form_step {
        margin: 0 0 3rem 0;
    }

    #loginM_01 .login_container {

        padding: 3rem;
        margin: 2% 0;
        min-height: 40rem;

    }

    #loginM_01 .login_container_wrap {
        width: 55%;
        margin: auto;
    }

    #loginM_01 .login_form .form_field {
        margin-bottom: 1rem;
        display: flex;
        width: 100%;
        align-items: center;
    }

    #loginM_01 .login_form .form_field:first-of-type {
        margin-top: 1rem;
    }

    #loginM_01 .login_form .form-label {
        width: 15vw;
        font-size: .85rem;
        margin-bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 0.5rem;
    }


    #loginM_01 .login_form .form_required {
        color: red
    }

    #loginM_01 .login_btnLogin {
        /* margin-left: 11.4vw; */
        width: 8rem;
        padding: 0.5rem;
    }


    #loginM_01 .login_form_reminder {
        padding: 1rem 0;
    }

    #loginM_01 .login_form_reminder_title {
        color: red;
        font-size: .85rem;
    }

    #loginM_01 .login_form_reminder_content {
        font-size: .8rem;
    }
    #loginM_01 .login_form_reminder_title {
        color: red;
        font-size: .85rem;
    }

    #loginM_01 .login_form_reminder_content {
        font-size: .8rem;
    }

    .login_btnLogin, .login_form .form-control{
        font-size: 1rem;
    }

}


/* verify Type */

#loginM_01 .verifyType_Tab {
    width: 22.7rem;
    height: 3rem;
    box-shadow: 1px 1px 4px 0px #00000059;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    position: relative;
    text-align: center;
    margin: 1.5rem auto;

}

#loginM_01 .verifyType_Tab .verify_type {
    margin: 1rem;
    padding: 0.5rem 0.8rem;
    font-size: .9rem;
    width: 12rem;
    border: 0;
    cursor: pointer;
    z-index: 2;
}


#loginM_01 .verify_type_line {
    position: absolute;
    height: 2.4rem;
    width: 11.3rem;
    margin-left: 0.3rem;
    border-radius: 0.8rem;
    box-shadow: inset 0.2rem 0.2rem 0.5rem #d1d1d191, inset -0.2rem -0.2rem 0.5rem #b8b8b891;
    background: rgb(255, 255, 255, 0.2);
    pointer-events: none;
}

#loginM_01 .verify_type:nth-child(1).active~.verify_type_line {
    transform: translateX(0);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

#loginM_01 .verify_type:nth-child(2).active~.verify_type_line {
    transform: translateX(10.8rem);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}


#loginM_01 .forgot_content .forgot_content_username,
#loginM_01 .forgot_content .forgot_content_password {
  cursor: pointer;
  display: inline;
}

#loginM_01 input[type="checkbox"]+label,
#loginM_01 .forgot_content {
  font-size: 1.15rem;

}

#loginM_01 .forgot_content {
  margin-left: auto;
}

#loginM_01 .input_error_msg {
    font-size: .8rem;
    margin: 0;
  }
  


