#forgotpwd_01 .forgotPassword_container_wrap {
    width: 70%;
    margin: auto;
    height: 70vh;
}

#forgotpwd_01 .forgotPassword_form_line {
    display: none;
}

#forgotpwd_01 .forgotPassword_form .form_field {
    margin-bottom: 1rem;
    position: relative;
}

#forgotpwd_01 .forgotPassword_form .form-label {
    display: flex;
    align-items: center;
    padding-right: 0.5rem;
}

#forgotpwd_01 .forgotPassword_form .form-control,
#forgotpwd_01 .forgotPassword_form .form-select {
    padding: 0.75rem
}

#forgotpwd_01 .forgotPassword_form .form_required {
    /* color: red; */
    margin-left: .25rem;
}

#forgotpwd_01 .forgotPassword_btnSubmit,
#forgotpwd_01 .forgotPassword_btnBack,
#forgotpwd_01 .forgotPassword_btnNext {
    width: 100%;
    padding: 0.75rem;
    height: auto;
}




#forgotpwd_01 .forgotPassword_btnBack+.forgotPassword_btnSubmit {
    margin: 1vw 0 0 0;
}

#forgotpwd_01 .forgotPassword_form_step3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#forgotpwd_01 .forgotPassword_btnSend {
    position: absolute;
    right: 0.8rem;
    font-size: .8rem;
    padding: 0 0.5rem;
    top: 0.7rem;
}

#forgotpwd_01 .forgotPassword_btnSendCode,
#forgotpwd_01 .forgotPassword_btnVerify {
    position: absolute;
    right: 0.8rem;
    font-size: .8rem;
    padding: 0.25rem 0.5rem;
    /* top: 25%; */
    transform: translate(0, 0%);
    min-width: 4rem;
    /* border-radius: .25rem; */
}

#forgotpwd_01 .forgotPassword_form_reminder {
    padding: 1rem 0;
}

#forgotpwd_01 .forgotPassword_form_reminder_title {
    font-size: 1.25rem;
}

#forgotpwd_01 .forgotPassword_form_reminder_content {
    font-size: 1rem;
}

#forgotpwd_01 .forgotPassword_stepper #RFS-StepMain button span {
    color: unset;
}

/* #forgotpwd_01 .forgotPassword_stepper #RFS-StepMain button {
    width: 2em;
    border: none;
    cursor: pointer;
    height: 2em;
    margin: 0 auto;
    display: flex;
    padding: 0px;
    font-size: 1em;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    background-color: rgb(224, 224, 224);
}

#forgotpwd_01 #RFS-StepperContainer #RFS-StepContainer:not(:first-child)::before {
    content: '';
    top: calc((2em - 1px) / 2);
    left: calc((-50% + 2em) - 8px);
    right: calc((50% + 2em) - 8px);
    position: absolute;
    border: 1px solid rgb(224, 224, 224);
}

#forgotpwd_01 .forgotPassword_stepper #RFS-StepMain button.active,
#forgotpwd_01 .forgotPassword_stepper #RFS-StepMain button.completed{
    background-color: green;
} */


#forgotpwd_01 .forgotPassword_stepper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0.5rem 0;
    margin: 0.5rem 0;

}

#forgotpwd_01 .forgotPassword_stepper button {
    width: 2em;
    border: none;
    cursor: pointer;
    height: 2em;
    margin: 0 auto;
    display: flex;
    padding: 0px;
    font-size: 1em;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    background-color: rgb(224, 224, 224);
    color: #000;
}

#forgotpwd_01 .forgotPassword_stepContainer {
    position: relative;
    width: 100%;
}

#forgotpwd_01 .forgotPassword_stepContainer:not(:first-child)::before {
    content: '';
    top: calc((2em - 1px) / 2);
    left: calc((-50% + 2em) - 8px);
    right: calc((50% + 2em) - 8px);
    position: absolute;
    height: .15rem;
    background: rgb(224, 224, 224);
}

#forgotpwd_01 .forgotPassword_stepContainer.active:not(:first-child)::before {
    background: green;
}

#forgotpwd_01 .forgotPassword_stepper .forgotPassword_stepContainer.active button {
    background-color: green;
    color: #fff
}

#forgotpwd_01 .forgotPassword_error_msg {
    /* color: red; */
    /* font-size: .8rem; */
    margin: 0;
}

#forgotpwd_01 .form_field_input {
    width: 100%;
}

#forgotpwd_01 .form_field_input.input_submit_wrap {
    text-align: end;
}

#forgotpwd_01 input[type="submit"]:disabled,
#forgotpwd_01 button:disabled {
    filter: opacity(0.5);
    cursor: no-drop;

}


.verifyDialog .form_field {
    display: flex;
    align-items: center;
}

.verifyDialog .form_field .form_field_input div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.verifyDialog .form_field .form_field_input div input {
    width: 50%;
}

.verifyDialog .modal-dialog {
    width: 30rem;
    margin: auto;
}

.verifyDialog .form-label {
    margin: 0 1rem 0 0;
    white-space: nowrap;
}

.verifyDialog input[type="button"],
.verifyDialog button {
    margin: 0 .5rem 0 0.5rem;

}

.verifyDialog input[type="button"],
.verifyDialog button,
.verifyDialog input[type="submit"] {
    padding: 0.25rem 0.5rem;
    height: auto;
    border-radius: 0.25rem;
    min-width: 5rem;
}

.verifyDialog input[type="button"]:disabled {
    filter: opacity(0.5);
    cursor: no-drop;
}

.verifyDialog .modal-footer {
    justify-content: flex-end;
}

.verifyDialog .error_msg {
    /* color: red; */
    font-size: .8rem;
    margin: 0;
}


/* Mobile to Desktop */
@media (min-width: 992px) {
    #forgotpwd_01 .forgotPassword_body {
        display: flex;
        align-items: center;
        justify-content: center;
        /* height: 75vh; */
    }

    #forgotpwd_01 .forgotPassword_form_line {
        display: block;
    }

    #forgotpwd_01 #forgotPassword_title_d {
        padding: .5rem;

    }

    #forgotpwd_01 .forgotPassword_form_step1,
    #forgotpwd_01 .forgotPassword_form_step2 {
        margin-bottom: 3rem;
    }

    #forgotpwd_01 .forgotPassword_container {

        padding: 3rem;
        margin: 2% 0;
        min-height: 40rem;

    }

    #forgotpwd_01 .forgotPassword_container_wrap {
        width: 55%;
        margin: auto;
    }

    #forgotpwd_01 .forgotPassword_form .form_field {
        margin-bottom: 1rem;
        display: flex;
        width: 100%;
        align-items: center;
    }

    #forgotpwd_01 .forgotPassword_form .form_field:first-of-type {
        margin-top: 1rem;
    }

    #forgotpwd_01 .forgotPassword_form .form-label {
        width: 15vw;
        font-size: .85rem;
        margin-bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 0.5rem;
    }

    #forgotpwd_01 .forgotPassword_form .form-check {
        margin-left: 11.8vw;
    }

    #forgotpwd_01 .forgotPassword_form .form-check-label {
        font-size: .75rem;
    }

    #forgotpwd_01 .forgotPassword_form .form_required {
        /* color: red */
    }


    #forgotpwd_01 .forgotPassword_btnNext,
    #forgotpwd_01 .forgotPassword_btnBack {
        /* margin-left: 11.4vw; */
        width: 8rem;
        padding: 0.5rem;
    }

    #forgotpwd_01 .forgotPassword_btnSubmit {
        width: 8rem;
        padding: 0.5rem;
        margin: 1rem;
    }

    #forgotpwd_01 .forgotPassword_btnBack+.forgotPassword_btnSubmit {
        margin: 0 0 0 1vw;
    }

    #forgotpwd_01 .forgotPassword_form_step3 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #forgotpwd_01 .forgotPassword_container_wrap #RFS-StepperContainer {
        padding: 0.85rem
    }

    #forgotpwd_01 .forgotPassword_form_reminder {
        padding: 0 1rem;
    }

    #forgotpwd_01 .forgotPassword_form_reminder_title {
        font-size: .85rem;
    }

    #forgotpwd_01 .forgotPassword_form_reminder_content {
        font-size: .8rem;
    }


}

/* verify Type */

#forgotpwd_01 .verifyType_Tab {
    width: 22.7rem;
    height: 3rem;
    box-shadow: 1px 1px 4px 0px #00000059;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    position: relative;
    text-align: center;
    margin: 1.5rem auto;
}

#forgotpwd_01 .verifyType_Tab .verify_type {
    margin: 1rem;
    padding: 0.5rem 0.8rem;
    font-size: .9rem;
    width: 12rem;
    border: 0;
    cursor: pointer;
}


#forgotpwd_01 .verify_type_line {
    position: absolute;
    height: 2.4rem;
    width: 11.3rem;
    margin-left: 0.3rem;
    border-radius: 0.8rem;
    box-shadow: inset 0.2rem 0.2rem 0.5rem #d1d1d191, inset -0.2rem -0.2rem 0.5rem #b8b8b891;
    pointer-events: none;
}

#forgotpwd_01 .verify_type:nth-child(1).active~.verify_type_line {
    transform: translateX(0);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

#forgotpwd_01 .verify_type:nth-child(2).active~.verify_type_line {
    transform: translateX(10.8rem);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}