@media (min-width: 992px) {

#myQRcodeModal.modal-dialog {
    width: 30rem;
    margin: auto;
}

}

#myQRcodeModal .modal-body {
    /* max-height: 32rem; */
    height: auto;
    overflow: auto;
}

.shareQR_userInfo{
    margin-bottom: 1rem;
    text-align: center;
}

/* .shareQR_userInfo span{
    font-size: 1.05rem;
} */

.shareQR_userInfo img{
    height: 5rem;
    margin-right: 1rem;
}

.shareQR_qrCode{
    text-align: center;
}
.shareQR_qrCode div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.shareQR_qrCode div label{
    margin: 1rem .5rem 1rem 0;
}
.shareQR_qrCode div img{
    height: 2rem;
}

.shareQR_shareMethod{
    display: flex;
    align-items: center;
    justify-content: center;
}
.shareQR_title{
    margin:1rem 0;
    text-align: center;
    width: 100%;
}
.shareQR_shareMethod button{
    margin: 0 .5rem 0 .5rem;
   
}
.shareQR_shareMethod img{
    height: 4rem;
    filter: drop-shadow(1px 1px 2px rgba(0,0,0,.5));
}