.floatingIcon_body{
    z-index: 5;
    cursor: move;
    /* display: inline-block; */
    /* width: 150px; */
    padding: 1rem;
    /* margin: auto; */
    display: flex;
    justify-content: center;
    position: fixed;
}



.floatingIcon_closeButton{
    position: absolute;
    top: 0;
    right: 0;
    font-size: 2.5rem;
    /* z-index: 99999; */
    cursor: pointer;
}

.desktop_floating{
    position: fixed;
}

/* .floatingIcon_container{
    position: fixed;

} */