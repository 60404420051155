.modal.loading_part {
    position: fixed;
    top: 0;
    left: 0;
    background: rgb(0, 0, 0, .4);
    z-index: 10000;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    margin: 0;
    max-width: initial;
    overflow: hidden;
}

.modal.loading_part.game{
    background: rgb(0, 0, 0, 1);
}

#loadingPart {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.loading_part div.modal-content {
    background: transparent;
    /* margin: auto;
    display: flex;
    justify-content: center;
    align-items: center; */
    border: 0;
    width: 100%;
    height: 100%;

}

.loading_part img {
    width: 2.9rem;
}

.loading_part div.loading_msg {
    height: auto;
    margin: 2rem 2.5rem 2rem 2rem;
    color: #fff;
    font-size: 2.5rem;
    padding: 1.5rem 3rem;
    color: #fff;
    /* background: #fff; */
    border-radius: 0.5rem;
}

.loading_part div.loading_msg .messageTitle {
    color: red;
    font-weight: 500;
}

.loading_part .loading_img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}