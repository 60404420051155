/* Slot GameProvider Tab Css */
#slot_01 {
  display: flex;
}

#slot_01 .slots_body {
  width: 100%;
}

#slot_01 .gameProviderTab_container {
  padding: 0.5rem;
  margin: 1.5rem 1rem;
  display: flex;
  align-items: center;
  height: 7rem;
}

#slot_01 .gameProviderTab_container button {
  height: 100%;
  padding: .25rem .5rem;
  background: transparent;
  font-size: 1.25rem;
}

#slot_01 .gameProviderTab {
  padding: 1rem 0;
  width: 100%;
  text-align: center;
  display: flex;
  flex-wrap: unset;
  /* overflow-x: auto; */
}


#slot_01 .gameProviderTab .gameProviderTab_selection {
  padding: 0.5rem 1.75rem;
  text-transform: uppercase;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: .75rem;
  /* color: unset; */
  margin: 0 .25rem;
  /* width: 100%; */
  /* filter: grayscale(1) opacity(0.5); */
  height: 5.5rem;
}

#slot_01 .gameProviderTab .gameProviderTab_selection.active {
  filter: none;
}

/* Game Type Tab CSS */

#slot_01 .slotType_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
  flex-direction: column;
}

#slot_01 .slotTypeTab {
  width: 100%;
  text-align: center;
  padding: 0.35rem;
  display: flex;
  flex-wrap: unset;
  overflow-x: auto;
  margin: 1rem 0 0;
}


#slot_01 .slotTypeTab .slotTypeTab_selection {
  padding: 0.5rem 1.75rem;
  text-transform: capitalize;
  white-space: nowrap;
  min-width: 6rem;
  font-size: 1rem;
  margin: 0 .25rem;
  cursor: pointer;
}

#slot_01 .search_container {
  position: relative;
  width: 100%;
  margin: 1rem;
}

#slot_01 .search_container input {
  padding: 1rem 0 1rem 2rem;
  /* border-radius: 2rem; */
}

#slot_01 .search_container svg {
  position: absolute;
  top: 50%;
  left: 1.25rem;
  transform: translate(-50%, -50%);
}

/* Slot Container */
#slot_01 #slotGameContainer {
  position: relative;
  min-height: 70vh;

}

#slot_01 .slotGameContainer_body_m {
  min-height: 70vh;
}

/* #slot_01 #slotGameContainer .card .hover-box {
  transition: opacity .3s, -webkit-transform .3s;
  transition: transform .3s, opacity .3s;
  transition: transform .3s, opacity .3s, -webkit-transform .3s;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
  -webkit-transform: translateY(20%);
  transform: translateY(20%);
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 85%;
  background: rgba(0, 0, 0, .5);
  -webkit-flex-direction: column;
  flex-direction: column;
} */

/* #slot_01 #slotGameContainer .game_btnPlayNow {
  white-space: nowrap;
  padding: 3px 8px;
  border-radius: 0.25rem;
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
} */

/* #slot_01 #slotGameContainer .card:hover {
  transform: translateY(-0.11rem);
}

#slot_01 #slotGameContainer .card:hover .hover-box {
  transform: translateY(0);
  opacity: 1;
} */

#slot_01 #slotGameContainer .card-body {
  padding: .7rem;
  z-index: 2;
  color: #fff;
}

#slot_01 #slotGameContainer .card-body .card-title {
  font-size: 1rem;
  margin: 0;
  width: 80%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* APP / LOBBY LAYOUT CSS */

/* DESKTOP */
.mslot_container {
  /* margin: 2rem 10rem 2rem; */
  /* max-width: 25%; */
}

.mslot_container .mslot_wrap {
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  /* padding: 2rem;
  background: #fff;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem; */
}

.mslot_container .mslot_imageWrap {
  width: 50%;
}

.mslot_container .mslot_imageWrap img {
  width: auto;
  margin: auto;
  /* text-align: center; */
  display: flex;
}

.mslot_container .mslot_title {
  /* border-bottom: 1px solid; */
  padding: 0 0 0.25rem;
  text-align: center;
  align-items: center;
  display: flex;
  /* width: 85%; */
  justify-content: center;
}

.mslot_container .mslot_title span {
  font-size: 1.25rem;
  padding-right: 0.5rem;
}

.mslot_container .mslot_title svg {
  cursor: pointer;
}

.mslot_container .mslot_userInfo {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}

.mslot_container .mslot_userInfo_row {
  margin: 1rem 0 .5rem;
  position: relative;
  display: flex;
  align-items: center;
}

.mslot_container .mslot_userInfo_row svg {
  position: absolute;
  right: 1rem;
  color: #000;
}



.mslot_container .mslot_contentWrap {
  padding: 0 1rem;
  /* width: 35%; */
  flex: 1;
}

.mslot_container .mslot_userInfo label {
  width: 6rem;
}

.mslot_container .mslot_userInfo input {
  /* border-radius: 1rem; */
  width: auto;
  text-align: center;
  flex: 1;
  /* padding: 0.25rem 3rem 0.25rem 1rem;
  background: #fff; */
}

.mslot_container .mslot_downloadButton {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 2rem;
  /* justify-content: space-between; */
}

.mslot_container .mslot_downloadButton a,
.mslot_container .mslot_downloadButton div {
  width: 31%;
  margin-bottom: 0.5rem;
  cursor: pointer;
  margin-right: 0.5rem;
}

/* MOBILE */
.mslot_container_m {
  /* margin: 0 5rem 1rem; */
  min-height: 70vh;
  /* max-width: 25%; */
}

.mslot_container_m .mslot_wrap {
  display: flex;
  /* justify-content: center; */
  align-items: stretch;
  padding: 1.5rem;
  flex-direction: column;
  border-radius: 0.5rem;
}

/* .mslot_imageWrap { */
/* width: 50%; */
/* } */

.mslot_container_m .mslot_imageWrap {
  width: 100%;
}

.mslot_container_m .mslot_imageWrap img {
  width: 15rem;
  margin: auto;
  /* text-align: center; */
  display: flex;
}

.mslot_container_m .mslot_title {
  border-bottom: 1px solid;
  padding: 1rem;
  text-align: center;
  margin-bottom: 1rem;
}

.mslot_container_m .mslot_title span {
  font-size: 1.25rem;
}

.mslot_container_m .mslot_userInfo {
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
}

.mslot_container_m .mslot_userInfo_row {
  margin: 1rem 0 .5rem;
  position: relative;
  display: flex;
  align-items: center;
}

.mslot_container_m .mslot_userInfo_row svg {
  position: absolute;
  right: 1rem;
  color: #000;
  font-size: 1.5rem;
}

.mslot_container_m .mslot_contentWrap {
  padding: 0 1rem;
}

.mslot_container_m .mslot_userInfo label {
  width: 25%;
  margin-right: 1rem;
}

.mslot_container_m .mslot_userInfo input {
  /* border-radius: 1rem; */
  width: 70%;
  padding: 0.25rem 1rem;
  background: #fff;
  opacity: 1;
}

.mslot_container_m .mslot_terms {
  white-space: pre-line;
  margin-bottom: 1rem;
}


.mslot_container_m .mslot_downloadButton {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.mslot_container_m .mslot_downloadButton a,
.mslot_container_m .mslot_downloadButton div {
  width: 47%;
  margin-bottom: 0.5rem;
  cursor: pointer;
  margin-right: 0.5rem;
  display: flex;
}

.mslot_container_m .mslot_downloadButton a img,
.mslot_container_m .mslot_downloadButton div img {
  width: 80%;
  margin: auto;
}

.mslot_downloadButton img {
  width: 10rem;
  margin: auto;
  cursor: pointer;
}

/* .mslot_downloadButton .appPlayNow_img  {
  width: 31%;
  margin-bottom: 0.5rem;
  cursor: pointer;
  margin:unset;
  margin-right: 0.5rem;
} */

.sort_container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}



.sortGames_container .form-select {
  width: 12rem;
  font-size: .95rem;
}


@media (min-width: 768px) {}

@media (min-width: 992px) {
  #slot_01 .slotType_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    flex-direction: row;
    margin-bottom: 1rem;
  }

  #slot_01 .slotTypeTab {
    width: unset;
    margin-top: 0;
    margin-right: 1rem;
  }

  #slot_01 .search_container {
    position: relative;
    width: unset;
    margin: unset;
  }

  #slot_01 .search_container svg {
    left: 1.25rem;
  }

  #slot_01 .search_container input {
    padding: 0.5rem 2rem 0.5rem 2rem;
    font-size: .85rem;
  }


  #slot_01 .slotTypeTab .slotTypeTab_selection {
    padding: 0.5rem 1.75rem;
    text-transform: capitalize;
    white-space: nowrap;
    min-width: 6rem;
    font-size: .85rem;
    margin: 0 .25rem;
  }

  .sort_container {
    width: unset;
   }

   .sortGames_container .form-select{
    margin-right: .5rem;
   }

}

#GameTermsModal .modal-body {
  white-space: pre-line;
}

#slot_01 .gameProviderTab::-webkit-scrollbar {
  height: .3rem;
}

#slot_01 .gameProviderTab::-webkit-scrollbar-track {
  background: #ccc;
}

#slot_01 .gameProviderTab::-webkit-scrollbar-thumb {
  background: var(--color-primary);
}


#slot_01 .title_container {
  display: flex;
  text-transform: uppercase;
  flex-direction: row;
}

#slot_01 .title_container a {
  flex: unset;
  width: 20%;
  display: flex;
  align-items: center;

}

#slot_01 .title_container a+div {
  width: 60%;

}

#slot_01 .title_container a+div select {
  border-radius: 20px;
  text-align: center;
  font-weight: 600;
  font-size: 1.25rem;

}

#slot_01 .title_back a {
  flex: unset;
  margin-right: .5rem;
}

#slot_01 .title_container::after {
  flex: unset;
  content: '';
}

.card_wrap .game_recommend_icon {
  position: absolute;
  z-index: 1;
  top: -1rem;
  left: -.75rem;
}

.card_wrap .game_new_icon {
  position: absolute;
  z-index: 1;
  /* top: -1rem;
  right: -.75rem; */
}

.card_wrap .game_recommend_icon img,
.card_wrap .game_new_icon img {
  height: 4rem;
  width: auto;
}

.gameProviderTab .gameProvider_hot_icon {
  position: absolute;
  z-index: 1;
  top: -1rem;
  left: -.75rem;
}

.gameProviderTab .gameProvider_hot_icon img {
  height: 2.85rem;
  width: auto;
}


