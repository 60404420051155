 /* ------------ Contact Us Start ------------*/
 #sidebar_contact_us_01 .contact_wrap_d {
   position: fixed;
   z-index: 3;
   right: 0;
   top: 20%;
   display: flex;
   overflow: hidden;
 }

 #sidebar_contact_us_01 .contact_front_d {
   writing-mode: vertical-rl;
   text-transform: uppercase;
   /* background: linear-gradient(to right, #00a4ff, #2e3192);
  color: #fff; */
   cursor: pointer;
 }

 #sidebar_contact_us_01 .contact_front_d .contact_front_d_header {
   display: flex;
   align-items: center;
 }

 #sidebar_contact_us_01 .contact_front_d .card-body {
   padding: 0.65rem 0.5rem 1.5rem;
   min-height: 12rem;
 }

 #sidebar_contact_us_01 .contact_front_d img {
   width: 2.5rem;
   padding: 0 0 0.25rem 0;
   margin-bottom: .8rem
 }

 #sidebar_contact_us_01 .contact_back_d {
   display: none;
 }

 /* #sidebar_contact_us_01 .contact_back_d.card {
   background: transparent;
   border: 0 !important;
   width: 15rem;
 } */

 #sidebar_contact_us_01 .contact_back_d .contact_back_d_header {
   padding: 1rem;
   display: flex;
   align-items: center;
 }

 #sidebar_contact_us_01 .contact_back_d .contact_back_d_header img {
   width: 2.5rem;
   margin-right: 1rem;
 }

 #sidebar_contact_us_01 .contact_back_d .contact_back_d_item {
   display: flex;
   align-items: center;
   /* padding: 0.75rem 1rem; */
 }

 #sidebar_contact_us_01 .contact_back_d .contact_back_d_item img {
   width: 3rem;
   margin-right: 1rem;
 }

 #sidebar_contact_us_01 .contact_back_d .contact_back_d_item a {
   font-size: .85rem;
   display: flex;
   align-items: flex-start;
   width: 100%;
 }

 #sidebar_contact_us_01 .contact_back_d .contact_back_d_item a+img {
   width: 3.5rem;
   margin: .25rem 0;
 }



 /* ContactUs Action */

 #sidebar_contact_us_01 .contact_wrap_d:hover .contact_front_d {
   opacity: 0;
   height: 70%;
 }

 #sidebar_contact_us_01 .contact_wrap_d:hover .contact_back_d {
   display: block;
   animation: fadeInRight 0.5s ease-in-out;
   filter: drop-shadow(2px 1px 6px rgb(0, 0, 0, 0.5));
 }

 #sidebar_contact_us_01 .contact_back_d .contact_back_d_item .contact_back_content {
   display: flex;
   flex-direction: column;
 }

 #sidebar_contact_us_01 .contact_back_d .contact_back_d_item .contact_back_content img {
   width: 5rem;
   margin-top: .25rem;
 }

 #sidebar_contact_us_01 .livechatButton {
   padding: 0.15rem .5rem;
   border-radius: .5rem;
   margin-top: .25rem;
 }

 @keyframes fadeInRight {
   0% {
     opacity: 0;
     right: -200px;
   }

   100% {
     opacity: 1;
     right: 0;
   }
 }

 /* ------------ Contact Us End ------------*/