#promotion_01 .promotion_body{
  display: flex;
  width: 100%;
  flex-direction: column;
}

/* Promotion Tab Css */
#promotion_01 .promoTab {
  background: #fff;
  /* width: 100%; */
  margin: 1.5rem 1rem;
  text-align: center;
  padding: .25rem;
  display: flex;
  flex-wrap: unset;
  overflow-x: auto;
}


#promotion_01 .promoTab .promoTab_selection {
  padding: 0.5rem 1.75rem;
  text-transform: uppercase;
  white-space: nowrap;
}


/* Promotion Wrap Container Css */

#promotion_01 .promotion_container {
  text-align: left;
}

#promotion_01 .promoContainer_body_m{
  min-height: 70vh;
}


@media (min-width: 768px) {
  #promotion_01 .promotion_container .col-md-4 {
    flex: 0 0 auto;
    width: 50%;
  }
}

@media (min-width: 992px) {
  #promotion_01 .promotion_container .col-md-4 {
    flex: 0 0 auto;
    width: 33.3333%;
  }
}