/* VIP Tab Css */



#vip_01 .vipTab {
  width: 100%;
  text-align: center;
  display: flex;
  flex-wrap: unset;
  justify-content: left;
  overflow: auto;

}

#vip_01 .vipTab .vipTab_selection {
  /* padding: 1.25rem 1.75rem; */
  padding: 0.65rem 1.75rem;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 1.15rem;
  margin: 0 0.5rem;
}

/* #vip_01 .vip_container {
  margin-top: 2rem;
  margin-bottom: 2rem;
} */





@media (min-width: 992px) {

  .header_afterLogin+.page section.vip_scrollContent,
  .header_beforeLogin+.page section.vip_scrollContent {
    /* margin-top: -6rem;
    padding-top: 5.9rem; */
    scroll-margin-top: 6rem;

  }

  #header_03.header_afterLogin+.page section.vip_scrollContent,
  #header_03.header_beforeLogin+.page section.vip_scrollContent {
    /* margin-top: -6rem;
    padding-top: 10.3rem; */
    scroll-margin-top: 9.25rem;
  }
}

/* .vip_scrollContent .section{
  background: #17002a;
} */


@media (max-width: 992px){
#vipPage {
    min-height: 85vh;
}

.header_afterLogin+.page section.vip_scrollContent{
  scroll-margin-top: 18rem;
}

.header_beforeLogin+.page section.vip_scrollContent {
  scroll-margin-top: 5rem;
}

#header_03.header_afterLogin+.page section.vip_scrollContent {
  scroll-margin-top: 18rem;
}

#header_03.header_afterLogin.headerEnableLoyalty+.page section.vip_scrollContent {
  scroll-margin-top: 20rem;
}


}


.vip_joinNowContent{
  text-align: center;
}

.vip_joinNowContent_title{
  font-size: 1.5rem;
}

.vip_joinNowContent_subtitle {
  margin:1rem 0 1.5rem;
}

.vip_joinNowContent_button button{
  min-width: 8rem;
  padding:.5rem .5rem .65rem;
  line-height: normal;

}