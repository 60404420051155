.promoDetails_content {
    white-space: inherit;
}

.promoDetails_title {

    font-weight: bold;
    font-size: 1.25rem;
    padding: 0.25rem 0;
    text-transform: uppercase;
    color: var(--color-theme);
}

.promoDetails_timeShow {
    /* padding-bottom: 1rem; */
}

#promoDetailsModal.modal-dialog {
    /* width: 45rem; */
    margin: auto;
}

#promoDetailsModal .modal-body {
    max-height: 50rem;
    height: auto;
    overflow: auto;
}

@media (max-width: 992px) {
    #promoDetailsModal .modal-content {
        /* height: 55rem; */
        height: 95%;
    }

    #promoDetailsModal.modal-dialog {

        width: 90%;
        margin: auto;
    }

    #promoDetailsModal .modal-body {
        max-height: initial;
        height: auto;
        overflow: auto;
    }

    .promoDetails_timeShow_title {
        font-weight: 500;
        font-size: 1rem;
    }
}

#promoDetailsModal .promoClaim_content {
    border-bottom: 1px dashed #000;
    padding: 1rem 0 0;
}



#promoDetailsModal .promoClaim_content:first-child {
    padding-top: 0
}

#promoDetailsModal .promoClaim_content .promoClaim_content_row {
    padding: 0 0 1rem;
}

#promoDetailsModal .promoClaim_content .promoClaim_content_row svg {
    color: var(--color-theme);
}

#promoDetailsModal .promoClaim_content .promoClaim_content_row:last-child {
    display: flex;
    align-items: center;
    font-size: .95rem;
}


#promoDetailsModal label {
    font-weight: 500;
    color: var(--color-theme);
}

.promoDetails_timeShow_title {
    font-weight: 500;
}

.promoDetails_timeShow_title+.promoDetails_timeShow_container {
    padding-bottom: 1rem;
}

.promoDetails_timeShow_container {
    display: flex;
    flex-direction: row;
    margin-top: 0.5rem;
}

.count_container {
    display: flex;
    flex-direction: row;
}

.count_box {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.count_container.day_count {
    margin-right: 1rem;
    border-radius: 0.35rem;
}

.count_box p:first-of-type {
    /* background: #f1f1f1; */
    /* border-radius: 0.35rem; */
    padding: 0.25rem 0.5rem;
    height: 3rem;
    vertical-align: middle;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.5rem;
    /* color: #000 */
}

.count_box p:last-of-type {
    font-size: .85rem;
}

.count_container.day_count .count_box p:first-of-type {
    border-radius: .35rem;
}

.count_container.time_count .count_box:first-of-type p:first-of-type {
    border-top-left-radius: .35rem;
    border-bottom-left-radius: .35rem;
}

.count_container.time_count .count_box:last-of-type p:first-of-type {
    border-top-right-radius: .35rem;
    border-bottom-right-radius: .35rem;
}


.count_container span {
    /* background: #f1f1f1; */
    height: 3rem;
    display: flex;
    align-items: center;
    /* color: #000 */
}