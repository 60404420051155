#announcement_01 .modal-dialog {
  width: 30rem;
  margin: auto;
}

#announcement_01 .modal-content {
  max-width: 30rem;
  margin: auto;
  overflow: hidden;
}

#announcement_01 .modal-footer {
  justify-content: left;
  border: 0;
}


#announcement_01 .announcement_header_title {
  display: flex;
  align-items: flex-end;
}

#announcement_01 .announcement_header_title span {
  vertical-align: bottom;
  line-height: 1;
  margin-left: .25rem;
}

#announcement_01 .announcement_content {
  padding-bottom: 0.5rem;
  white-space: pre-line;
  /* width: 90%; */
  max-height: 25rem;
  overflow: auto;
  min-height: 20rem;
font-size: .9rem;
}

#announcement_01 .announcement_content p {
  margin-bottom: 1rem;
}

#announcement_01 .announcement_btnClose {
  white-space: nowrap;
  padding: 0.25rem 0.5rem;
  min-width: 6rem;
  font-size: 1rem;
}

#announcement_01 .swiper-pagination {
  position: relative;
  text-align: center;
}

#announcement_01 .swiper-pagination-bullet {
  width: .5rem;
  height: .5rem;
}

#announcement_01 .modal-body{
  padding-bottom: 0;
}

.ann_img{
  margin-bottom: 1rem;
}

.announcement_header::before{
content:'';
width:100%;
height:10px;
background: green;
}