

.section.pageTitle.mobile {
  font-weight: bold;
}



.title_container {
  display: flex;
  text-transform: capitalize;
  flex-direction: row;
}

.title_container a {
  flex: 1
}

.title_back a {
  flex: unset;
  margin-right: .5rem;
}

.title_container::after {
  flex: 1;
  content: '';
}

.desc_container {
  text-align: center;
  margin: 1rem auto;
  line-height: 1.65rem;
}


.desc_container a {
  color: red;
  text-decoration: underline;
  
}

@media (min-width: 992px) {

  #title.section {
    padding: 2rem;
  }

  .title_container a {
    visibility: hidden;
  }
}

/* DROPDOWN TITLE */
.dropdownTitle .title_container a {
  flex: unset;
  width: 20%;
  display: flex;
  align-items: center;
}

.dropdownTitle .title_container a+div {
  width: 60%;
}

.dropdownTitle .title_container a+div select {
  border-radius: 20px;
  text-align: center;
  font-weight: 600;
  font-size: 1.25rem;
}