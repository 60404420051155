.alertMessage {
    animation: popUp .3s ease 0s 1 normal forwards;
    /* background: rgb(0,0,0,0.2); */
}


.alertMessage_body {
    padding: 1rem
}

/* .alertMessage .modal-body {
    display: flex;
    justify-content: center;
} */
.alertMessage_header.modal-header {
    padding: 0;
    border: 0;
    justify-content: center;
    font-size: 1.25rem;
    font-weight: 700;

}

/* 
.alertMessage_header_title{
} */
.alertMessage .modal-dialog {
    width: 25rem;
    margin: auto;
    max-width: 100%;
}



.alertMessage_body .modal-footer {
    border-top: 0;
    justify-content: center;
    padding: 0;
}

button.alertMessage_btnClose {
    padding: 0.25rem 1rem;
    min-width: 8rem;
    /* border-radius: .25rem; */
    font-size: 1rem;
}

/* .alertMessage_content {
    word-wrap: break-word;
    display: flex;
    justify-content: center;
    align-items: center;
} */

.alertMessage .modal-body {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem
}

.alertMessage_content {
    width: 100%;
    word-wrap: break-word;
    text-align: center;
    font-size: 1.15rem;
    font-weight: 600;
}



.alertMessage_content svg {
    margin-right: .5rem;
    margin-bottom: 0.25rem;
    /* color: red; */
    font-size: 1.25rem;
}

@keyframes popUp {
    0% {
        transform: scale(.7);
    }

    45% {
        transform: scale(1.05);
    }

    80% {
        transform: scale(.95);
    }

    100% {
        transform: scale(1);
    }
}


@media (max-width: 992px) {
    .alertMessage_content svg {
        font-size: 1.5rem;
    }
}

.alertMessage.point{
    animation: none;
}

.alertMessage.point .modal-dialog {
    width: 30rem;
    margin: auto;
    max-width: 100%;
}

.alertMessage.point .alertMessage_content {
    text-align: start;
}


.alertMessage.point .alertMessage_body{
    padding: 0;
}

.alertMessage.point .alertMessage_header {
    padding: 1rem;
    border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
}
.alertMessage.point .modal-body{
    padding: 1rem
}

.alertMessage.point .modal-body a{
    color:rgba(var(--bs-link-color-rgb),var(--bs-link-opacity,1))
}

.alertMessage.point .modal-dialog .modal-header .btn-close {
    background-image: var(--bs-btn-close-bg);
    opacity: 1;
}

.point_subTitle{
    text-transform: uppercase;
    font-weight: 600;
    display: flex;
    align-items: center;
    margin-bottom: .5rem;
}

.point_subTitle img{
    height: 2rem;
    margin-right: .5rem;
}