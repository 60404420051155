/* ------------------- Footer Start --------------------- */
#footer_01 .section {
    margin-top: auto;
}

/* #footer_01 .footer_row:not(:last-child) {
    border-bottom: 1px dashed #fff;
} */

#footer_01 .footer_row {
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

#footer_01 .footer_row.footer_menu_container {
    padding-top: 0rem;
    padding-bottom: 0rem;
}

#footer_01 .footer_row .footer_menu_wrap {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

/* ---- ------- Footer Row 1 (provider) ---- ------- */

#footer_01 .footer_row .footer_info {
    text-align: center;
    width: 100%;
}

#footer_01 .footer_row .footer_info .footer_title {
    text-transform: capitalize;
    white-space: nowrap;
    margin-bottom: .25rem;
    /* font-size: 1rem; */
}

#footer_01 .footer_row .footer_info .footer_info_img {
    display: flex;
    justify-content: center;
    padding-left: 0;
    list-style: none;
    flex-wrap: wrap;
}

#footer_01 .footer_row .footer_info .footer_info_img li {
    margin-right: .25rem;
    margin-bottom: .75rem;
}

#footer_01 .footer_row .footer_info .footer_info_img li img {
    height: 2rem;
}

#footer_01 .footer_row .footer_info.footer_follow_us .footer_info_img li img {
    height: 2.5rem;
}


/* ---- ------- Footer Row 2 (help & copyright) ---- ------- */

#footer_01 .footer_row .footer_copyright_box {
    display: flex;
    justify-content: left;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
}

#footer_01 .footer_row .footer_help {
    display: flex;
    /* flex-direction: column; */
    width: 100%;
    /* text-align: center; */
}

#footer_01 .footer_help a {
    border-bottom: 1px solid;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    text-decoration: none;
}

#footer_01 .footer_row .footer_copyright_box .footer_copyright {
    padding-top: 0.5rem;
}

#footer_01 .footer_row .footer_version_box {
    font-size: .8rem;
}

/* Desktop to Mobile */
@media (max-width: 992px) {
    #footer_01 .footer_gaming_license {
        grid-area: gaming_license;
    }

    #footer_01 .footer_follow_us {
        grid-area: follow_us;
    }

    #footer_01 .footer_payment_method {
        grid-area: payment_method;
        margin-top: 1rem;
    }

    #footer_01 .footer_licenses_wrap {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: "gaming_license follow_us" "payment_method payment_method";

    }

    #footer_01 .footer_row .footer_info .footer_title {
        text-align: left;
    }

    #footer_01 .footer_row .footer_info .footer_info_img {
        justify-content: left;
    }
}


/* Mobile to Desktop */
@media (min-width: 992px) {

    #footer_01 .footer_row .footer_copyright_box {
        display: flex;
        justify-content: center;
        flex-direction: unset;
        align-items: center;
    }

    #footer_01 .footer_row .footer_help {
        display: flex;
        flex-direction: row;
        width: unset;
    }

    #footer_01 .footer_help a {
        margin-left: 0.25rem;
        margin-right: 0.25rem;
        border-bottom: none;
        /* border-right: 1px solid ; */
        /* padding-right: 0.5rem; */
        padding-top: 0;
        padding-bottom: 0;
    }

    #footer_01 .footer_row .footer_copyright_box .footer_copyright {
        padding-top: 0;
    }

    #footer_01 .footer_row .footer_version_box {
        font-size: .8rem;
    }

    #footer_01 .footer_row .footer_info {
        text-align: center;
        width: 33.3%;
    }

    #footer_01 .footer_info_start .footer_row .footer_info {
        text-align: start;
        width: 33.3%;
    }

    #footer_01 .footer_info_start .footer_row .footer_info .footer_info_img {
        display: flex;
        justify-content: start;
        padding-left: 0;
        list-style: none;
        flex-wrap: wrap;
    }

    #footer_01 .footer_row .footer_info .footer_title {
        font-size: 1rem;
    }

    #footer_01 .footer_row .footer_info .footer_info_img li img {
        height: 2rem;
    }

    #footer_01 .footer_row .footer_info.footer_follow_us .footer_info_img li img {
        height: 2rem;
    }

}

/* ------------------- Footer End --------------------- */

/* ---- ------- Footer SEO Start ---- ------- */
#footer_01 .seo_footer_content span {
    color: red;
    text-decoration: underline;
    cursor: pointer;
}

#footer_01 .footer_seo_all {
    display: flex;
}

#footer_01 .footer_seo_all_col {
    width: 50%;
    padding: 10px;
    border-right: 1px solid #777;
    white-space: pre-line;
}

#footer_01 .footer_seo_all_col:first-child {
    padding-right: 10px;
    padding-left: 0;
}

#footer_01 .footer_seo_all_col>.footer_seo__title {
    color: #00a4ff;
    font-size: 1.1rem;
    padding-bottom: 0.25rem;
}

#footer_01 .footer_seo__title {

    font-size: 12px;
    font-weight: 500;
    padding-top: 15px;
}

#footer_01 .footer_seo__title:first-child {
    padding-top: 0;
}

/* ---- ------- Footer SEO End ---- ------- */
.footer_container {
    display: flex;
    flex-direction: column;
}

.footer_container_horizontal {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
}

.footer_container_horizontal .footer_info_container {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-between;
}

#footer_01 .footer_container_horizontal .footer_row.footer_menu_container {
    width: 20%;
    border: 0;
    padding: 1rem;
}

#footer_01 .footer_container_horizontal .footer_row .footer_help {
    flex-direction: column;
    text-align: left;

}

#footer_01 .footer_container_horizontal .footer_row .footer_help div {
    padding: .25rem 0;
}

#footer_01 .footer_container_horizontal .footer_info_container_wrap {
    width: 80%;
    height: 100%;
}

#footer_01 .footer_copyright_container {
    width: 100%;
}

/* ------ BOTTOM NAV --------- */



.bottomNav {
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 6rem;
    background: #fff;
}

.bottomNav_selection {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 20%;
}

.bottomNav_selection span {
    white-space: nowrap;
}

.bottomNav_selection img {
    height: 3.5rem;
    width: auto;
}


.footer_info_summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #ccc;
}

.footer_info_summary::after {
    content: '▼';
    transition: transform 0.2s;
}

details[open] .footer_info_summary::after {
    transform: rotate(180deg);
}

.footer_info_selection {
    color: #ccc;
    padding: .5rem;

}

.footer_info_selection div {
    color: #84848a;
}

details>summary {
    list-style: none;
}

details>summary::-webkit-details-marker {
    display: none;
}

.footer_info_text {
    padding: .5rem;
    border-bottom: 1px solid #ccc
}

.footer_info_summary>.footer_info_text {
    margin-right: auto;
    border: 0;
}