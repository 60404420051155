#register_01 .register_container_wrap {
    width: 70%;
    margin: auto;
    min-height: 70vh;
}

#register_01 .register_form_line {
    display: none;
}

#register_01 .register_form .form_field {
    margin-bottom: 1rem;
    position: relative;
}

#register_01 .register_form .form-label {
    display: flex;
    align-items: center;
    padding-right: 0.5rem;
}

#register_01 .register_form .form-check-label {
    font-size: 1rem;
}

#register_01 .register_form .form-check-label a {
    color: red;
    text-decoration: underline;
    cursor: pointer;
}


#register_01 .register_form .form-control,
#register_01 .register_form .form-select {
    padding: 0.75rem
}

#register_01 .register_form .form_required {
    /* color: red; */
    margin-left: .25rem;
}

#register_01 .register_btnNext,
#register_01 .register_btnBack {
    width: 100%;
    padding: 0.75rem;
    margin-top: 0.5rem;
    height: auto;
}


#register_01 .register_btnDepositNow {
    min-width: 10rem;
    padding: 0.75rem;
    margin: 1rem;
}

#register_01 .register_btnBack+.register_btnNext {
    margin: 1vw 0 0 0;
}

#register_01 .register_form_step3 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#register_01 .register_form_step3 img {
    margin: 1.25rem auto;
    width: auto;
    max-height: 16rem;
}

#register_01 .register_btnSendCode,
#register_01 .register_btnVerify {
    position: absolute;
    right: 0.8rem;
    font-size: .8rem;
    padding: 0.25rem 0.5rem;
    /* top: 25%; */
    transform: translate(0, 0%);
    min-width: 4rem;
}

#register_01 .register_btnSendCode {
    width: auto;
}

#register_01 .register_form_reminder {
    padding: 1rem 0;
}

#register_01 .register_form_reminder_title {
    font-size: 1.25rem;
}

#register_01 .register_form_reminder_content {
    font-size: 1rem;
}

#register_01 .register_form_reminder_content span{
   display: block;
}

#register_01 .register_stepper #RFS-StepMain button span {
    color: unset;
}

#register_01 .register_stepper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 0.5rem 0;
    margin: 0.5rem 0;

}

#register_01 .register_stepper button {
    width: 2em;
    border: none;
    cursor: pointer;
    height: 2em;
    margin: 0 auto;
    display: flex;
    padding: 0px;
    font-size: 1em;
    align-items: center;
    border-radius: 50%;
    justify-content: center;
    /* background-color: rgb(224, 224, 224);
    color: #000; */
}

#register_01 .register_stepContainer {
    position: relative;
    width: 100%;
}

#register_01 .register_stepContainer:not(:first-child)::before {
    content: '';
    top: calc((2em - 1px) / 2);
    left: calc((-50% + 2em) - 8px);
    right: calc((50% + 2em) - 8px);
    position: absolute;
    height: .15rem;
    
}

/* #register_01 .register_stepContainer.active:not(:first-child)::before { */
    /* background: green; */
/* } */

/* #register_01 .register_stepper .register_stepContainer.active button {
    background-color: green;
    color: #fff
} */

/* #register_01 .register_stepper #RFS-StepContainer:not(:first-child) #RFS-StepMain button::before {
    content: '';
    top: calc((2em - 1px) / 2);
    left: calc((-50% + 2em) - 8px);
    right: calc((50% + 2em) - 8px);
    position: absolute;
    border: 1px solid rgb(48, 34, 90);
}  */

#register_01 #RFS-Connector {
    display: none;
}

#register_01 .register_error_msg,
#verifyDialog .register_error_msg {
    /* color: red; */
    /* font-size: .8rem; */
    margin: 0;
}

#register_01 .form_field_input {
    width: 100%;
    position: relative;
}

#register_01 .form_field_input.input_submit_wrap {
    text-align: end;
}

#register_01 input[type="submit"]:disabled,
#register_01 button:disabled {
    filter: opacity(0.5);
    cursor: no-drop;

}

.verifyDialog .form_field {
    display: flex;
    align-items: center;
}

.verifyDialog .form_field .form_field_input div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.verifyDialog .form_field .form_field_input div{
    display: flex;
    flex: 1;
    align-items: center;
    margin-left: 0.5rem;
}

.verifyDialog .form_field .form_field_input div input {
    flex: 1;
    width: 50%;
    margin-right: 0;
}

.verifyDialog .modal-dialog {
    width: 30rem;
    margin: auto;
}

.verifyDialog .form-label {
    margin: 0 1rem 0 0;
    white-space: nowrap;
}

.verifyDialog input[type="button"],
.verifyDialog button {
    margin: 0 .5rem 0 0.5rem;

}

.verifyDialog input[type="button"],
.verifyDialog button,
.verifyDialog input[type="submit"] {
    padding: 0.25rem 0.5rem;
    height: auto;
    border-radius: 0.25rem;
    min-width: 5rem;
}

.verifyDialog input[type="button"]:disabled {
    filter: opacity(0.5);
    cursor: no-drop;
}

.verifyDialog .modal-footer {
    justify-content: flex-end;
}

.verifyDialog .error_msg {
    /* color: red; */
    font-size: .8rem;
    margin: 0;
}

/* Mobile to Desktop */
@media (min-width: 992px) {
    #register_01 .register_body {
        display: flex;
        align-items: center;
        justify-content: center;
        /* height: 75vh; */
    }

    #register_01 .register_form_line {
        display: block;
    }

    #register_01 #register_title_d {
        padding: .5rem;

    }

    #register_01 .register_form_step1,
    #register_01 .register_form_step2 {
        margin-bottom: 3rem;
    }

    #register_01 .register_container {

        padding: 3rem;
        margin: 2% 0;
        min-height: 40rem;

    }

    #register_01 .register_container_wrap {
        width: 55%;
        margin: auto;
    }

    #register_01 .register_form .form_field {
        margin-bottom: 1rem;
        display: flex;
        width: 100%;
        align-items: center;
    }

    #register_01 .register_form .form_field:first-of-type {
        margin-top: 1rem;
    }

    #register_01 .register_form .form-label {
        width: 15vw;
        font-size: .85rem;
        margin-bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 0.5rem;
    }


    /* #register_01 .register_form .form-check { */
    /* margin-left: 11.8vw; */
    /* } */

    #register_01 .register_form .form-check-label {
        font-size: .75rem;
    }

    #register_01 .register_form .form_required {
        /* color: red */
    }

    #register_01 .register_btnNext,
    #register_01 .register_btnBack {
        /* margin-left: 11.4vw; */
        width: 8rem;
        padding: 0.5rem;
    }

    #register_01 .register_btnDepositNow {
        width: 8rem;
        padding: 0.5rem;
        margin: 1rem;
    }

    #register_01 .register_btnBack+.register_btnNext {
        margin: 0 0 0 1vw;
    }

    #register_01 .register_form_step3 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #register_01 .register_container_wrap #RFS-StepperContainer {
        padding: 0.85rem
    }

    #register_01 .register_form_reminder {
        padding: 0 1rem;
    }


    #register_01 .register_form_reminder_title {
        font-size: .85rem;
    }

    #register_01 .register_form_reminder_content {
        font-size: .8rem;
    }

    #register_01 .register_successful_content {
        white-space: pre-line;
        text-align: center;
    }

    #register_01 .register_btnSendCode {
        width: auto;
    }

    #register_01 .register_form .form-control, #register_01 .register_form .form-select{
        font-size: 1rem;
    }

}

@media (max-width: 992px) {

    #register_01 .register_btnNext,
    #register_01 .register_btnBack,
    #register_01 .register_btnDepositNow {
        font-size: 1.25rem;
    }
}


.toggleAffInput{
    cursor: pointer;
    transition: transform 0.3s ease;
    /* transform: rotate(-180deg); */
    display: flex;
    margin-top: 3px;
    margin-left: 0.25rem;
}
.toggleAffInput.showInput{
    transform: rotate(-180deg);

}

.register_form_step3 .register_form_reminder_title{
    padding-left:.25rem;
    font-size: 1rem;
}