.affiliate_body {
  position: relative;
}

/* .affiliate_button_container{
max-width: 50%;
margin:auto;
} */
.affiliate_button_wrap {
  position: absolute;
  z-index: 2;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.affiliateButton {
  font-size: 1rem;
  min-width: 25%;
  line-height: normal;
}

/* ------------------- ADJUST CSS -------------------  */
.affiliate_button_wrap .affiliateButton {
  border-radius: 1rem;
  margin-bottom: 1.25rem;
  margin-right: 1rem;
  padding: 0.5rem 1rem;
}

.affiliate_button_wrap_m .affiliateButton {
  border-radius: 1rem;
  margin-bottom: .5rem;
  margin-right: 1rem;
  padding: 0.5rem 1rem;
}