#forgotUser_01 .forgotUsername_container_wrap {
    width: 70%;
    margin: auto;
    height: 70vh;
}

#forgotUser_01 .forgotUsername_form_line {
    display: none;
}

#forgotUser_01 .forgotUsername_form .form_field {
    margin-bottom: 1rem;
    position: relative;
}

#forgotUser_01 .forgotUsername_form .form-label {
    display: flex;
    align-items: center;
    padding-right: 0.5rem;
}

#forgotUser_01 .forgotUsername_form .form-control,
#forgotUser_01 .forgotUsername_form .form-select {
    padding: 0.75rem
}

#forgotUser_01 .forgotUsername_form .form_required {
    /* color: red; */
    margin-left: .25rem;
}

#forgotUser_01 .forgotUsername_btnSubmit {
    width: 100%;
    padding: 0.75rem;
    height: auto;
}

/* #forgotUser_01 .forgotUsername_btnSend {
    position: absolute;
    right: 0.8rem;
    font-size: .8rem;
    padding: 0 0.5rem;
    top: 0.7rem;
} */

#forgotUser_01 .forgotUsername_btnSendCode,
#forgotUser_01 .forgotUsername_btnVerify {
    position: absolute;
    right: 0.8rem;
    font-size: .8rem;
    padding: 0.25rem 0.5rem;
    /* top: 25%; */
    transform: translate(0, 0%);
    min-width: 4rem;
    /* border-radius: .25rem; */
}


#forgotUser_01 .forgotUsername_form_reminder {
    padding: 1rem 0;
}

#forgotUser_01 .forgotUsername_form_reminder_title {
    font-size: 1.25rem;
}

#forgotUser_01 .forgotUsername_form_reminder_content {
    font-size: 1rem;
}

#forgotUser_01 .forgotUsername_form_step {
    margin: 3rem 0 0 0;
}

#forgotUser_01 .forgotUsername_error_msg {
    /* color: red; */
    /* font-size: .8rem; */
    margin: 0;
}

#forgotUser_01 .form_field_input {
    width: 100%;
}

#forgotUser_01 .form_field_input.input_submit_wrap {
    text-align: end;
}

#forgotUser_01 input[type="submit"]:disabled,
#forgotUser_01 button:disabled {
    filter: opacity(0.5);
    cursor: no-drop;
}

.verifyDialog .form_field {
    display: flex;
    align-items: center;
}

.verifyDialog .form_field .form_field_input div:first-child {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.verifyDialog .form_field .form_field_input div input {
    width: 50%;
}

.verifyDialog .modal-dialog {
    width: 30rem;
    margin: auto;
}

.verifyDialog .form-label {
    margin: 0 1rem 0 0;
    white-space: nowrap;
}

.verifyDialog input[type="button"],
.verifyDialog button {
    margin: 0 .5rem 0 0.5rem;

}

.verifyDialog input[type="button"],
.verifyDialog button,
.verifyDialog input[type="submit"] {
    padding: 0.25rem 0.5rem;
    height: auto;
    border-radius: 0.25rem;
    min-width: 5rem;
}

.verifyDialog input[type="button"]:disabled {
    filter: opacity(0.5);
    cursor: no-drop;
}

.verifyDialog .modal-footer {
    justify-content: flex-end;
}

.verifyDialog .error_msg{
    /* color: red; */
    font-size: .8rem;
    margin: 0;
}




/* Mobile to Desktop */
@media (min-width: 992px) {
    #forgotUser_01 .forgotUsername_body {
        display: flex;
        align-items: center;
        justify-content: center;
        /* height: 75vh; */
    }

    #forgotUser_01 .forgotUsername_form_line {
        display: block;
    }

    #forgotUser_01 #forgotUsername_title_d {
        padding: .5rem;

    }

    #forgotUser_01 .forgotUsername_form_step {
        margin: 0 0 3rem 0;
    }

    #forgotUser_01 .forgotUsername_container {

        padding: 3rem;
        margin: 2% 0;
        min-height: 40rem;

    }

    #forgotUser_01 .forgotUsername_container_wrap {
        width: 55%;
        margin: auto;
    }

    #forgotUser_01 .forgotUsername_form .form_field {
        margin-bottom: 1rem;
        display: flex;
        width: 100%;
        align-items: center;
    }

    #forgotUser_01 .forgotUsername_form .form_field:first-of-type {
        margin-top: 1rem;
    }

    #forgotUser_01 .forgotUsername_form .form-label {
        width: 15vw;
        font-size: .85rem;
        margin-bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 0.5rem;
    }


    #forgotUser_01 .forgotUsername_form .form_required {
        /* color: red */
    }

    #forgotUser_01 .forgotUsername_btnSubmit {
        /* margin-left: 11.4vw; */
        width: 8rem;
        padding: 0.5rem;
    }


    #forgotUser_01 .forgotUsername_form_reminder {
        padding: 0 1rem;
    }

    #forgotUser_01 .forgotUsername_form_reminder_title {
        font-size: .85rem;
    }

    #forgotUser_01 .forgotUsername_form_reminder_content {
        font-size: .8rem;
    }


}


/* verify Type */

#forgotUser_01 .verifyType_Tab {
    width: 22.7rem;
    height: 3rem;
    box-shadow: 1px 1px 4px 0px #00000059;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    position: relative;
    text-align: center;
    margin: 1.5rem auto;

}

#forgotUser_01 .verifyType_Tab .verify_type {
    margin: 1rem;
    padding: 0.5rem 0.8rem;
    font-size: .9rem;
    width: 12rem;
    border: 0;
    cursor: pointer;
    z-index: 2;
}


#forgotUser_01 .verify_type_line {
    position: absolute;
    height: 2.4rem;
    width: 11.3rem;
    margin-left: 0.3rem;
    border-radius: 0.8rem;
    box-shadow: inset 0.2rem 0.2rem 0.5rem #d1d1d191, inset -0.2rem -0.2rem 0.5rem #b8b8b891;
    background: rgb(255, 255, 255, 0.2);
    pointer-events: none;
}

#forgotUser_01 .verify_type:nth-child(1).active~.verify_type_line {
    transform: translateX(0);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

#forgotUser_01 .verify_type:nth-child(2).active~.verify_type_line {
    transform: translateX(10.8rem);
    transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}