.section_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: end;
}

.section_title {
    margin-bottom: .5rem;
    /* text-transform: uppercase; */
    display: flex;
    align-items: center;
    /* font-weight: 600; */
}

/* .section_title img {
    max-width: 2.5rem;
    margin-right: 0.5rem;
    width: auto;
    height: 2rem;
} */

.section_details {
    margin-left: .25rem;
    margin-right: .25rem;
    text-decoration: none;
}

.dynamic_template_2 .section_header {
    padding-top: 1.5rem
}

/* ----------- -Include Content- ----------- */

#feedback_02 .card_feedback {
    position: relative;
    top: 0;
    padding: 2rem;
    height: 100%;
    width: 100%;
    left: 0;
}


#feedback_02 .card_feedback.bgImg_feedback_card {
    position: absolute;
    top: 0;
    padding: 2rem;
    height: 100%;
    width: 100%;
    left: 0;
}

#feedback_02 .feedback_user_wrap {
    display: flex;
    align-items: center;
}

#feedback_02 .card_feedback .feedback_user_wrap img {
    height: 3rem;
}

#feedback_02 .card_feedback .feedback_rating_wrap .feedback_rating {
    text-align: center;
}

#feedback_02 .card_feedback .feedback_rating_wrap .feedback_rating img {
    height: 1.5rem;
}

#feedback_02 .feedback_content_wrap,
#feedback_02 .feedback_avatar {
    width: 100%;
}

#feedback_02 .feedback_userInfo {
    padding-left: .75rem
}

#feedback_02 .feedback_username,
#feedback_02 .feedback_rating {
    line-height: normal;
}

#feedback_02 .section_header.titlePosition_center {
    justify-content: center;
}

#feedback_02 .card_feedback.contentPosition_center .feedback_user_wrap {
    justify-content: center;
}

#feedback_02 .card_feedback.contentPosition_center .feedback_content_wrap {
    text-align: center;
}

#feedback_02 .feedback_subject {
    line-height: normal;
    max-height: 3.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

#feedback_02 .feedback_desc {
    /* height: 8rem;
    overflow-y: auto; */
    /* max-height: 7.5rem; */
    font-size: .85rem;
    overflow: hidden;
    text-overflow: ellipsis;

    /* height: 5rem; */
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    display: -webkit-box;
    /* font-size: 1rem; */
    /* max-height: 6rem; */
    overflow: hidden;
    padding-right: 10px;
    text-overflow: ellipsis;
    /* width: 50%; */
    word-break: break-word;
    min-height: 6.5rem;
    /* padding: 1rem; */
    margin-bottom: 1rem;
}

#feedback_02 .feedback_body_m .feedback_subject {
    line-height: normal;
    max-height: 2.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    display: -webkit-box;
    word-break: break-word;
    min-height: 2.5rem;
}

#feedback_02 .feedback_body_m .feedback_desc {
    /* height: 5rem; */
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    word-wrap: break-word;
    display: -webkit-box;
    /* font-size: 1rem; */
    /* max-height: 7rem; */
    overflow: hidden;
    padding-right: 10px;
    text-overflow: ellipsis;
    /* width: 50%; */
    word-break: break-word;
    min-height: 6rem;
    /* padding:1rem; */
    margin-bottom: 1rem;
}

#feedback_02 #feedback_container .feedback_body_m .section_header {
    font-size: 1.65rem;

}

#feedback_02 #feedback_container .feedback_body_m .section_header.titlePosition_center {

    text-align: center;
}

/* ------------------------------ */