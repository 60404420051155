/* ------ Speaker Announcement Start ------- */

#speaker_announcement_01 .speakerAnnouncement_container {
  display: flex;
  align-items: center;
  flex-direction: row;

}



#speaker_announcement_01 .speakerAnnouncement_iconContainer {
  margin: 0 .5rem 0 0;
  display: flex;
  align-items: center;
}

#speaker_announcement_01 .speakerAnnouncement_content {
  padding: .25rem 0;
  overflow-y: hidden;
}

.speakerAnnouncement_titleContainer{
  font-size: 1.25rem;
}
.marquee{
  margin-right: 1.5rem;
}

.speakerAnnouncement_content p{
  margin-right: 20rem;
  background: transparent;

}
/* ------ Speaker Announcement End ------- */