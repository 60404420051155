/* ------ Ranking List (Desktop/Mobile) Start ------- */


/* #ranking_01 .rankingList_body  {
  padding: 2rem 0;
} */

#ranking_01 .rankingList_info {
  /* color: #f1931e; */
  text-transform: capitalize;
  font-size: 1rem;
}

#ranking_01 .section_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: end;
}

#ranking_01 .section_title {
  margin-bottom: .5rem;
  /* text-transform: uppercase; */
  display: flex;
  align-items: center;
  /* font-weight: 600; */
}

/* #ranking_01 .section_title img {
  max-width: 2.5rem;
  margin-right: 0.5rem;
  width: auto;
  height: 2rem;
} */

#ranking_01 .section_details {
  margin-left: .25rem;
  margin-right: .25rem;
  text-decoration: none;
}


.rankingList_table_container .rankingList_table {
  max-height: 35rem;
  overflow-y: scroll;
}


/* ---- Ranking List (Mobile) Start ----- */

/* .rankingListlist_table .nav-item button{
   color:#fff;
  } */

/* #ranking_01 .rankingList_tableTab .rankingList_table_selection {
  background: transparent;
  color: #fff;

} */

/* #ranking_01 .rankingList_tableTab .rankingList_table_selection.active {
  background: #fff;
  color: #000;
} */

/* #ranking_01 .rankingList_table_body { */
/* padding-top: .5rem;
  padding-right: 1.5rem;
  padding-bottom: .5rem;
  padding-left: 1.5rem; */
/* } */


#ranking_01 .rankingList_table_body .rankingList_table_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-bottom: 1px solid #fff; */
  flex-direction: row;
  width: 100%;
}

#ranking_01 .rankingList_table_body .rankingList_table_row .rankingList_user img {
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  width: 4rem;
}

#ranking_01 .rankingList_user {
  display: flex;
  flex-direction: row;
  align-items: center;

}




/* ---- Ranking List (Mobile) End ----- */


@media (min-width: 992px) {
  #ranking_01 .rankingList_username_col {
    margin-left: 1rem;
  }

  #ranking_01 .rankingList_table_body .rankingList_table_row {
    flex: 0 0 32%;
  }

  .rankingList_username {
    font-size: 1.05rem;
  }

  .rankingList_amount {
    font-size: 1.05rem;
  }

  #ranking_01 .rankingList_info {
    /* color: #f1931e; */
    text-transform: capitalize;
    font-size: 1rem;
  }

  .rankingList_table_container .rankingList_table {
    max-height: unset;
    overflow: unset;
  }

  .rankingList_table_container .rankingList_table_body {
  column-gap: 1.65rem;
  }

}

/* ---- Ranking List (Desktop) End ----- */

/* ------ Ranking List (Desktop/Mobile) End ------- */

.rankingList_tableTab .rankingList_table_selection {
  font-size: 1.25rem;
}



.rankingList_table_container .rankingList_table_body {
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
}