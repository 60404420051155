#transferBalanceModal.modal-dialog{
    max-width: 30rem;
    margin: auto;
}

.transferBalance_header{
    align-items: flex-start;
}

.transferBalance_title{
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 1.25rem;
    justify-content: space-evenly;
}

.transferBalance_title div{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.transferBalance_title img{
    width: 10rem;
}
.transferBalance_btnCancel{
    width: 50%;
    padding: 0.5rem 0;
    margin: 1rem .25rem 1rem 0;
    border-radius:5px;
}
.transferBalance_button{
    display:flex;
    align-items: center;
    justify-content:center;
}

.transferBalance_btnSubmit{
    width: 50%;
    padding: 0.5rem 0;
    margin: 1rem  0;
    border-radius:5px;
    border: 1px solid var(--color-button);
}
.transferBalance_btnSubmit:disabled{
    opacity: 0.5;
    cursor: not-allowed;
}

.transferBalance_form .form-label{
    display: flex;
    align-items: center;
}


@media (max-width: 992px) {
    #transferBalanceModal.modal-dialog{
        width: 75%;
        margin:auto
    }

    #depositConfirmDialog.modal-dialog{
        width: 75%;
        margin:auto
    }
}

