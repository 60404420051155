    /* #games_01 .main_game {
      padding: 2rem 0;
    } */


    #games_01 .section_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: end;
    }

    #games_01 .section_title {
      margin-bottom: .5rem;
      /* text-transform: uppercase; */
      display: flex;
      align-items: center;
      /* font-weight: 600; */
    }

    /* #games_01 .section_title img {
      max-width: 2.5rem;
      margin-right: 0.5rem;
      width: auto;
      height: 2rem;
    } */

    #games_01 .section_details {
      margin-left: .25rem;
      margin-right: .25rem;
      text-decoration: none;
    }

    #games_01 .main_game.section {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
    }

    /* 
    #games_01 .main_gameSelection {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .main_gameSelection .main_gameSelection_menu {
      display: flex;
      align-items: center;
      flex-direction: column;
    } */

    /* .main_gameSelection .main_gameSelection_menu .main_gameSelection_image{ */
    /* overflow: hidden; */
    /* } */

    /* .main_gameSelection .main_gameSelection_menu.active{
    background: #0166D1;
    color:#ffffff;
  } */

    #games_01 .main_game_wrap {
      display: flex;
      flex-direction: row;
    }

    #games_01 .main_game_container {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      max-height: 60.5rem;
      overflow: auto;
    }

    #games_01 .main_game_container a {
      display: flex;
      align-items: center;
      flex-direction: column;
      font-size: var(--h5-font-size);
    }

    /* #games_01 .main_game_wrap_SL a,
    #games_01 .main_game_wrap_LC a {
      width: 50%;
    } */

    /* #games_01 .main_game_wrap_SP a,
    #games_01 .main_game_wrap_APP a,
    #games_01 .main_game_wrap_FI a,
    #games_01 .main_game_wrap_LO a {
      width: 50%;
      margin-top:1rem;
    } */

    /* #games_01 .main_game_container a {
      width: 50%;
    } */


    /* #games_01 .main_gameSelection_menu img {
      width: auto;
      max-height: 7rem;
      border-radius: inherit;
    } */

    #games_01 .main_gameSelection_width {
      width: 100%;
    }


    /* Desktop to Mobile */
    @media (max-width: 992px) {

      #games_01 .main_gameSelection .main_gameSelection_menu {
        font-size: 1.25rem;
        /* white-space: nowrap; */
      }

      #games_01 .main_game_name {
        font-size: 1.25rem;
      }

    }



    @media (max-width: 450px) {

      #games_01 .main_gameSelection .main_gameSelection_menu {
        font-size: 1.35rem;
      }

      #games_01 .main_game_name {
        font-size: 1.25rem;
        text-align: center;
      }

    }

    @media (max-width: 340px) {

      #games_01 .main_gameSelection .main_gameSelection_menu {
        font-size: 1.25rem;
      }

      #games_01 .main_game_name {
        font-size: 1.25rem;
        text-align: center;
      }

    }

    /* vertical layout*/
    #games_01 .main_game_wrap_vertical {
      display: flex;
      flex-direction: column;
    }

    #games_01 .vertical_col {
      width: 100%;
    }

    #games_01 .main_game_wrap_vertical .main_gameSelection {
      padding-top: 1rem;
      padding-bottom: 1rem;
      display: flex;
      align-items: center;
      flex-direction: row;
      overflow-x: auto;
      overflow-y: hidden;

    }

    /* #games_01 .main_game_wrap_vertical .main_gameSelection .main_gameSelection_menu{ */
    /* width: 25%; */
    /* } */


    #games_01 .main_game_wrap_vertical .main_game_container {

      margin-top: 1rem;
      padding: 1rem;
      min-height: 10rem;
      max-height: 35rem;
    }

    #games_01 .main_game_wrap_vertical .main_gameSelection_width {
      width: 25%;
      min-width: 25%;
    }

    #games_01 .main_game_wrap_vertical .main_gameSelection_width.auto_width {
      width: auto;
      min-width: unset;
    }

    #games_01 .main_game_wrap_vertical .main_gameSelection_menu img {
      max-height: 4rem;
    }


    /* #games_01 .main_game_wrap_vertical .main_game_container a {
     padding:.5rem .25rem
    } */


    /* IF GAME SELECTION MENU IS Horizontal */
    #games_01 .main_gameSelection {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    #games_01 .main_gameSelection .main_gameSelection_menu {
      display: flex;
      align-items: center;
      flex-direction: row;
    }

    /* #games_01 .main_gameSelection .main_gameSelection_menu img {
      width: auto;
      max-height: 3rem;
      border-radius: inherit;
    } */

    /* IF GAME SELECTION MENU IS Vertical */

    #games_01 .main_gameSelection_vertical {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    #games_01 .main_gameSelection_vertical .main_gameSelection_menu {
      display: flex;
      align-items: center;
      flex-direction: column;
      white-space: pre;
    }

    /* #games_01 .main_gameSelection_vertical .main_gameSelection_menu img {
      width: auto;
      max-height: 7rem;
      border-radius: inherit;
    } */


    #games_01 .main_gameSelection_image+div {
      flex: 1;
    }


    #games_01 .vertical_col .main_gameSelection_horizontal .main_gameSelection_width {
      width: 33.3%;
      min-width: 33.3%;
    }

    #games_01 .vertical_col .main_gameSelection_horizontal .main_gameSelection_width.auto_width{
      width: auto;
      min-width: unset;
    }

    #games_01 .vertical_col .main_gameSelection_horizontal .main_gameSelection_menu {
      font-size: 1.5rem;
    }

    #games_01 .vertical_col .main_gameSelection_horizontal .main_gameSelection_menu img {
      max-height: 3.5rem;
    }


    #games_01 .main_game_container.slider_main_game_container {
      display: block;
      overflow: hidden;
      max-height: unset;
      margin-top: 0;
    }


    #games_01 #mainGame_game_01 .swiper-pagination-bullet-active {
      width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 8px));
      border-radius: 50%;
      transition: all .25s;
    }


    /* Mobile to Desktop */
    @media (min-width: 992px) {

      #games_01 .main_game_container a {
        width: 20%;
        text-align: center;
      }

      #games_01 .main_game_container.slider_main_game_container a {
        width: auto;
      }

      #games_01 .vertical_col .main_gameSelection_horizontal .main_gameSelection_width.auto_width {
        width: auto;
        min-width: unset;
      }

   

    }



