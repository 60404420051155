/* ---------- selection provider ---------------*/

.selectProviderContainer {
    position: relative;
  
  }
  
  .selectProviderContainer .game_wallet_dropdown.providerSection_DEPOSIT {
    width: 60%;
    right: 50%;
    transform: translate(50%,0);
  }

  .selectProviderContainer .game_wallet_dropdown {
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  
  .selectProviderContainer .game_wallet_dropdown table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 .5rem;
    font-size: .8rem;
  }
  
  .selectProviderContainer .game_wallet_dropdown table tr th:nth-child(3),
  .selectProviderContainer .game_wallet_dropdown table tr th:nth-child(4) {
    width: 30px;
    font-size: .9rem;
    text-align: center;
    line-height: normal
  }
  
  .selectProviderContainer .game_wallet_dropdown table tr td:nth-child(3),
  .selectProviderContainer .game_wallet_dropdown table tr td:nth-child(4) {
    text-align: center;
    padding: 0
  }
  
  .selectProviderContainer .game_wallet_dropdown table tbody tr {
    /* background: #fff;
    color: #808080; */
    margin: 0.3rem 0;
    margin-bottom: .5rem;
    padding: 0.5rem .8rem;
    border-radius: .5rem;
    cursor: pointer;
  }
  
  .selectProviderContainer .game_wallet_dropdown table tbody tr td:first-child {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }
  
  .selectProviderContainer .game_wallet_dropdown table tbody tr td:last-child {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }
  
  .selectProviderContainer .game_wallet_dropdown table tr td {
    padding: 0.5rem .8rem;
  }
  
  .selectProviderContainer .game_wallet_dropdown div.main_wallet {
    margin: 0.3rem 0;
    display: flex;
    align-items: center;
    padding: 0.5rem .8rem;
    border-radius: .5rem;
    justify-content: space-between;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: .5rem;
    font-size: .9rem;
    cursor: pointer;
  }
  
  .selectProviderContainer .game_wallet_dropdown li.main_wallet div:nth-child(2) {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  
  .selectProviderContainer .game_wallet_dropdown li:not(.main_wallet) div:nth-child(2) {
    margin-right: 1.5rem;
  }
  
  .selectProviderContainer img {
    width: 1.5rem;
  }
  
  .selectProviderContainer .gameWallet_accordion .accordion-button {
    font-size: .8rem;
    padding: .45rem .75rem;
    display: flex;
    justify-content: space-between;
  }
  
  .selectProviderContainer .game_wallet_dropdown table tbody tr td:first-child {
    width: 47%;
  }
  
  
  .selectProviderContainer .game_wallet_dropdown:after {
    content: " ";
    position: absolute;
    left: 50%;
    top: -7px;
    border-top: none;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    transform: translate(-50%, 0);
  }
  
  
  
  
  
  .selectProviderContainer .form-select {
    height: 3rem;
    cursor: pointer;
  }
  
  .selectProviderContainer .gameWallet_accordion.accordion {
    margin: 0;
    overflow: hidden;
    max-height: 20rem;
    /* height: 20rem; */
    /* height: 100%;  */
  }
  
  .selectProviderContainer .gameWallet_accordion .accordion-item {
    margin-bottom: .5rem;
  }
  
  .selectProviderContainer .game_wallet_dropdown table tbody tr:last-child,
  .selectProviderContainer .gameWallet_accordion .accordion-item:last-child {
    margin-bottom: 0;
  
  }
  
  .selectProviderContainer .game_wallet_dropdown table {
    margin-bottom: -6px;
  }
  
  .selectProviderContainer .gameWallet_accordion .gameWallet_accordion_icon {
    margin: 0;
  }
  
  
  .selectProviderContainer .main_wallet div:first-child {
    width: 50%;
  }
  
  /* .selectProviderContainer  .main_wallet .gameWallet_amount {
    min-width: 5rem;
    text-align: end;
  } */
  
  .selectProviderContainer .accordion-header .gameWallet_amount {
    min-width: 3rem;
    text-align: end;
  }
  
  .selectProviderContainer .game_wallet_dropdown_body .gameWallet_amount {
    min-width: 3rem;
    padding-right: 23%;
    text-align: end;
  
  }
  
  /* desktop to mobile */
  @media (max-width: 992px) {
  
    .selectProviderContainer .game_wallet_dropdown,
    .selectProviderContainer .game_wallet_dropdown.providerSection_DEPOSIT {
      position: relative;
      width: 100%;
      z-index: 1;
    }
  
    .selectProviderContainer .game_wallet_dropdown:after {
      content: " ";
      position: absolute;
      left: 50%;
      top: -7px;
      border-top: none;
      border-right: 10px solid transparent;
      border-left: 10px solid transparent;
      transform: translate(-50%, 0);
    }
  
    .selectProviderContainer .game_wallet_dropdown div.main_wallet {
      margin: 0.3rem 0;
      display: flex;
      align-items: center;
      padding: 0.75rem .8rem;
      border-radius: .5rem;
      justify-content: space-between;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: .75rem;
      font-size: 1.25rem;
    }
    .selectProviderContainer .gameWallet_accordion.accordion {
        margin: 0;
        overflow: hidden;
        max-height: 30rem;
      }
    .selectProviderContainer .gameWallet_accordion .accordion-button {
      font-size: 1.175rem;
      padding: .75rem;
    }
  
    .selectProviderContainer .form-select {
      height: 3.5rem;
      display: flex;
      align-items: center;
    }
  
  
    .selectProviderContainer .game_wallet_dropdown table {
      width: 100%;
      border-collapse: separate;
      border-spacing: 0 .5rem;
      font-size: 1.175rem;
    }
  
    .selectProviderContainer .accordion-header .gameWallet_amount {
      min-width: 4rem;
  
    }
  
    .selectProviderContainer .game_wallet_dropdown_body .gameWallet_amount {
      min-width: 3rem;
      padding-right: 23%;
      text-align: end;
  
    }
  
    .selectProviderContainer img {
      width: 2rem;
    }
  
    .selectProviderContainer .gameWallet_accordion .gameWallet_accordion_icon {
      width: 1.95rem;
      height: 1.95rem;
    }
  
    .selectProviderContainer .gameWallet_accordion .accordion-item {
      margin-bottom: .75rem;
    }
  
    .selectProviderContainer .game_wallet_dropdown {
      padding: 1rem;
    }
  
    .selectProviderContainer .game_wallet_dropdown table tr td {
      padding: .75rem .8rem
    }
  
  }